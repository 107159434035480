// CHAT HOME ===============================================

.chat-home {
  &.content {
    padding-top: 5rem;
    padding-bottom: 0.5rem;
    min-height: 100vh;
    @media (max-width: 767.98px) {
      padding-top: 0;
    }
  }
  .avatar {
    position: relative;
    .actif {
      width: 11px;
      height: 11px;
      border-radius: 13px;
      background-color: $success;
      position: absolute;
      display: block;
      left: -16px;
      bottom: 4px;
    }
  }

  // :host ::ng-deep network-menu-mobile {
  //   display: block;
  //   width: 100%;
  //   transform: translate(0px, 43px);
  // }

  .all-icons {
    position: fixed;
    width: 100%;
    bottom: 67px;
    left: 0;
    background: white;
    justify-content: space-around;
    display: flex;
    padding: 12px;
    border-bottom: 1px solid #b9b9b9;
  }

  @media (max-width: 480px) {
    .p-sidebar-left {
      width: 100% !important;
    }
    .p-sidebar .p-sidebar-header {
      padding: 0.5rem;
    }
  }

  .chat-messages {
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 158px);
    background-color: $home-gray;
    border-radius: $round-card;
    padding: 1rem 2rem 6rem 2rem;
    // HIDE SCROLL
    overflow-y: scroll;
    -ms-overflow-style: none !important;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
    &.no-messages {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100vh;
      //max-height: calc(100vh - 136px);
      background-color: #f0f2f5;
      border-radius: 13px;
      padding: 1rem 1rem 1rem 1rem;
      overflow-y: hidden;
      margin: 1rem 0;
    }
    @media (max-width: 767.98px) {
      max-height: calc(100vh - 65px);
      border-radius: 0;
      //padding: 5rem 1rem 2rem 1rem;
      padding: 0;
    }
    .date-message {
      position: relative;
      display: flex;
      justify-content: center;
      margin-bottom: 1rem;
      &:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 1px;
        background-color: $gray-300;
        display: block;
        top: 6px;
        left: 0;
        z-index: 0;
      }
      span {
        background-color: $home-gray;
        padding: 0 1rem;
        z-index: 1;
        display: inline-block;
        position: relative;
        width: auto;
      }
    }
  }

  

  .input-search-topbar {
    position: absolute !important;
    z-index: 12;
    background: white;
    height: 50px;
    width: 100%;
    formly-form {
      width: calc(100% - 50px);
      .search-input datnek-ds-svg-icon {
        top:16px !important;
      }
    }
    input[type="text"] {
      height: 58px;
      margin-top: -5px;
      font-size: 15px;
      display: inline-block;
      border: none;
      outline: none;
      color: #555;
      padding: 0rem 2.2rem;
      width: 100%;
      background: none;
      z-index: 3;
      transition: width 0.4s cubic-bezier(0, 0.795, 0, 1);
      cursor: text;
      @media (max-width: 560px) {
        padding: 0rem 1rem;
        font-size: 16px;
        margin-top: -4px;
      }
      &:focus, &:focus-visible, &:focus-within, &:active {
        border: none !important;
        outline: none !important;
      }
    }
    network-button-icon, datnek-ds-button-icon {
      position: absolute;
      right: 5px;
      top: 6px;
    }
  }

  .search-list {
    width: 100%;
    height: auto;
    position: absolute;
    left: 0;
    top: 58px;
    z-index: 10;
    .list-item {
      border-bottom: 1px solid $gray-slate-300;
      background-color: $white;
      transition: all ease-in 0.15s;
      &:hover {
        background-color: $secondary;
      }
    }
  }

  @media (max-width: 767.98px) {
    .top-chat {
      position: fixed;
      padding: 0.5rem;
      top: 0;
      z-index: 1000;
      background: white;
      width: 100%;
    }
  }

  .searchbox {
    background-color: $white;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 65px;
    z-index: 1;
    @media (max-width: 767.98px) {
      top: -12px;
      padding: 12px;
    }
    :host ng-deep network-search-input {
      width: 100%;
    }
  }
  :host ng-deep input {
    margin-bottom: 0 !important;
  }
  .respond-message {
    position: relative;
    z-index: 10;
    //bottom: 65px;
    //left: 0;
    width: 100%;
    padding: 0 0.5rem 0.5rem 0.5rem;
    //min-height: 86px;
    background-color: $white;
    @media (max-width: 767.98px) {
      //bottom: 67px;
    }
    .message {
      background-color: $home-gray;
      border-radius: $round-card;
      padding: $card-padding;
      position: relative;
      .close {
        position: absolute;
        right: 5px;
        top: 5px;
      }
      .name-poster {
        //text-transform: uppercase;
      }
    }
  }
  .chat-form {
    position: absolute;
    width: 100%;
    bottom: 0;
    background-color: $white;
    z-index: 12;
    @media (max-width: 767.98px) {
      position: fixed;
    }

    .icons {
      // width: 130px;
      @media (max-width: 767.98px) {
        width: auto;
      }
    }
    .input {
      width: 100%;
      //gap: 16px;
      .network-editor-add-comments, .datnek-ds-editor-add-comments {
        width: 100%;
      }
    }
  }

  .sidebar-users .p-component {
    height: calc(100vh - 407px) !important;
    @media (min-width: 768px) and (max-width: 991.98px) {
      height: calc(100vh - 670px) !important;
    }
  }

  @media (max-width: 767.98px) {
    .sidebar-left-chat {
      position: fixed;
      width: 100%;
      height: 100%;
      top: 0;
      background: white;
      overflow-y: scroll;
    }
    .close {
      position: fixed;
      right: 0;
      top: 0;
    }
    .side-header {
      z-index: 1;
      position: relative;
      network-user-infos {
        width: 90% !important;
      }
      .d-inline-block {
        width: 10% !important;
      }
    }
    .user-infos-details {
      position: fixed;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: $white;
      z-index: 100;
      border-top: 1px solid $gray-slate-300;
      .details-scroll {
        height: calc(100vh - 75px);
        overflow-y: scroll;
      }
    }
  }

  .sidebar-chat {
    max-height: calc(100vh - 410px);
    //overflow-y: scroll;
    //padding-top: 12px;
    -ms-overflow-style: none !important;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .sidebar-media-chat {
    max-height: 165px;
    //overflow-y: scroll;
    -ms-overflow-style: none !important;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .sidebar-chat-epingle {
    max-height: calc(100vh - 550px);
    //overflow-y: scroll;
    -ms-overflow-style: none !important;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
    user-chat-list {
      padding-top: -3rem;
    }
    .user-chat-box {
      padding-top: 0;
    }
  }

  network-card-user-box {
    position: relative !important;
    margin-top: 16px;
    top: unset !important;
  }

  .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    background: #ffffff;
    border-color: $primary;
    border-width: 0 0 4px 0;
    color: $primary;
    padding: 0.7rem 0.3rem !important;
  }

  .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    @media (min-width: 992px) {
      padding: 0.8rem 0.7rem !important;
    }
    padding: 1rem 0.7rem !important;
  }

  .p-tabview-nav-link {
    @media (min-width: 992px) {
      padding: 0.8rem 0.7rem !important;
    }
    padding: 1rem 0.7rem !important;
  }

  .p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none;
  }

  .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    border-color: $transparent;
  }

  .p-tabview .p-tabview-nav li {
    margin-right: 0;
    border-color: $primary;
    border-width: 0 0 4px 0;
    color: $text;
  }

  .p-tabview .p-tabview-nav {
    background: #ffffff;
    border: 0px solid #dee2e6;
    border-width: 0 0 0px 0;
  }

  .py-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .px-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .flex-grow-0 {
    flex-grow: 0 !important;
  }
  .border-top {
    border-top: 1px solid #dee2e6 !important;
  }

  .p-tabview .p-tabview-panels {
    background: #ffffff;
    padding: 0.5rem 0 !important;
    border: 0 none;
    color: $text;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    margin-top: 0.4rem;
  }

  .link-item {
    padding: 0.5rem;
    border-radius: 13px;
    border: 1px solid $gray-slate-300;
    margin-bottom: 0.4rem;
    network-link {
      font-size: 0.9rem;
    }
  }
}

.chat-message {
    display: flex;
    flex-direction: column;
    &.chat-home {
      padding: 5rem 0 0 0;
    }
  }

.chat-messages {
  .chat-messages {
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 158px);
    background-color: $home-gray;
    border-radius: $round-card;
    padding: 1rem 2rem 6rem 2rem;
    // HIDE SCROLL
    overflow-y: scroll;
    -ms-overflow-style: none !important;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
    &.no-messages {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100vh;
      //max-height: calc(100vh - 136px);
      background-color: #f0f2f5;
      border-radius: 13px;
      padding: 1rem 1rem 1rem 1rem;
      overflow-y: hidden;
      margin: 1rem 0;
    }
    @media (max-width: 767.98px) {
      max-height: calc(100vh - 65px);
      border-radius: 0;
      padding: 5rem 1rem 2rem 1rem;
    }
    .date-message {
      position: relative;
      display: flex;
      justify-content: center;
      margin-bottom: 1rem;
      &:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 1px;
        background-color: $gray-300;
        display: block;
        top: 6px;
        left: 0;
        z-index: 0;
      }
      span {
        background-color: $home-gray;
        padding: 0 1rem;
        z-index: 1;
        display: inline-block;
        position: relative;
        width: auto;
      }
    }
  }

  .input-search-topbar {
    position: absolute !important;
    z-index: 12;
    background: white;
    height: 50px;
    width: 100%;
    formly-form {
      width: calc(100% - 50px);
      .search-input datnek-ds-svg-icon {
        top:16px !important;
      }
    }
    input[type="text"] {
      height: 58px;
      margin-top: -5px;
      font-size: 15px;
      display: inline-block;
      border: none;
      outline: none;
      color: #555;
      padding: 0rem 2.2rem;
      width: 100%;
      background: none;
      z-index: 3;
      transition: width 0.4s cubic-bezier(0, 0.795, 0, 1);
      cursor: text;
      @media (max-width: 560px) {
        padding: 0rem 1rem;
        font-size: 16px;
        margin-top: -4px;
      }
      &:focus, &:focus-visible, &:focus-within, &:active {
        border: none !important;
        outline: none !important;
      }
    }
    network-button-icon, datnek-ds-button-icon {
      position: absolute;
      right: 5px;
      top: 6px;
    }
  }

  .search-list {
    width: 100%;
    height: auto;
    position: absolute;
    left: 0;
    top: 58px;
    z-index: 10;
    .list-item {
      border-bottom: 1px solid $gray-slate-300;
      background-color: $white;
      transition: all ease-in 0.15s;
      &:hover {
        background-color: $secondary;
      }
    }
  }

  @media (max-width: 768px) {
    .top-chat {
      position: fixed;
      padding: 0.5rem;
      top: 0;
      z-index: 1000;
      background: white;
      width: 100%;
    }
  }

  .searchbox {
    background-color: $white;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 65px;
    z-index: 1;
    @media (max-width: 767.98px) {
      top: -12px;
      padding: 12px;
    }
    :host ng-deep network-search-input {
      width: 100%;
    }
  }
  :host ng-deep input {
    margin-bottom: 0 !important;
  }
  .respond-message {
    position: relative;
    z-index: 10;
    //bottom: 65px;
    //left: 0;
    width: 100%;
    padding: 0 0.5rem 0.5rem 0.5rem;
    //min-height: 86px;
    background-color: $white;
    @media (max-width: 767.98px) {
      //bottom: 67px;
    }
    .message {
      background-color: $home-gray;
      border-radius: $round-card;
      padding: $card-padding;
      position: relative;
      .close {
        position: absolute;
        right: 5px;
        top: 5px;
      }
      .name-poster {
        //text-transform: uppercase;
      }
    }
  }
  .chat-form {
    position: absolute;
    width: 100%;
    bottom: 0;
    background-color: $white;
    z-index: 12;
    @media (max-width: 767.98px) {
      position: fixed;
    }

    .icons {
      // width: 130px;
      @media (max-width: 767.98px) {
        width: auto;
      }
    }
    .input {
      width: 100%;
      //gap: 16px;
      .network-editor-add-comments, .datnek-ds-editor-add-comments {
        width: 100%;
      }
    }
  }
}

.menu-right {
  position: relative;
  width: 100%;
  height: 100vh;
  background-color: $white;
  datnek-ds-button-icon, .close {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 10000;
  }
}

.chat-message {
  .menu-right {
    position: absolute;
    width: 100%;
    top: 0;
    height: 100vh;
    z-index: 1000;
    background-color: white;
  }
}

// CHAT CALL =====================================================
.chat-call {
  .datnek-ds-chat-call {
    margin-bottom: 0 !important;
    z-index: 10;
    @media (max-width: 767.98px) {
      height: auto;
    }

    .header-chat-call {
      display: flex;
      height: 58px;
      align-items: center;
      padding: 16px;
      border-bottom: 1px solid $gray-slate-300;
      position: relative;
      img {
        width: $img-tiny;
      }
      .icon-close {
        position: absolute;
        right: 10px;
        top: 10px;
      }
    }
    .content-modal {
      // background: aquamarine;
      @media (max-width: 767.98px) {
        height: calc(100vh - 116px);
      }
    }
  }

  .shared-screen {
    background-color: $text;
    width: 100%;
    height: 100%;
    .screen {
      width: 75%;
      height: calc(100vh - 145px);
    }
    .participants {
      width: 25%;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: $card-padding;
      .user {
        background-color: $home-gray;
      }
    }
  }

  .sidebar-chat {
    max-height: calc(100vh - 265px);
  }

  .container-fluid {
    height: calc(100vh - 145px);
    top: 0;
  }

  .call-header {
    height: 45px;
    width: 100%;
    border-bottom: 1px solid lightgrey;
  }

  .call-body {
    height: calc(100vh - 146px);
    overflow: hidden;
    background-color: $home-gray;
    z-index: 3;
    .users {
      width: 100%;
      background-color: $white;
      height: 100%;
      padding: 0 1rem 0 2rem;
      @media (max-width: 575.98px) {
        height: 100vh;
      }
    }
    .messages {
      width: 100%;
      background-color: $white;
      height: 100%;
    }
    .user-grid {
      display: grid;
      // grid-template-columns: repeat(2, 1fr);
      // grid-template-rows: repeat(2, 16vw);
      height: calc(100vh - 145px);
      align-items: center;
      grid-gap: 0px;
      width: 100%;
      .user {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: inset 0px 0px 0px 4px $gray-slate-300;
        padding: 2rem;
        z-index: 1;
        &.active {
          box-shadow: inset 0px 0px 0px 4px $primary;
        }
      }
      &.one-user {
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: repeat(1, 1fr);
        .user {
          &:nth-child(1) {
            grid-column-start: 1;
            grid-column-end: 3;
            grid-row-start: 1;
            grid-row-end: 3;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
      &.two-user {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(1, 1fr);
        .user {
          &:nth-child(1) {
            grid-column-start: 1;
            grid-column-end: 2;
            grid-row-start: 1;
            grid-row-end: 2;
          }
          &:nth-child(2) {
            grid-column-start: 2;
            grid-column-end: 2;
            grid-row-start: 1;
            grid-row-end: 2;
          }
        }
        @media (max-width: 575.98px) {
          grid-template-columns: repeat(1, 1fr);
        }
      }
      &.three-user {
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(1, 1fr);
        // .user {
        //   &:nth-child(1) {
        //     grid-column-start: 1;
        //     grid-column-end: 2;
        //     grid-row-start: 1;
        //     grid-row-end: 2;
        //   }
        //   &:nth-child(2) {
        //     grid-column-start: 2;
        //     grid-column-end: 2;
        //     grid-row-start: 1;
        //     grid-row-end: 2;
        //   }
        // }
        @media (max-width: 575.98px) {
          grid-template-columns: repeat(2, 1fr);
          .user {
            // &:nth-child(1) {
            //   grid-column-start: 1;
            //   grid-column-end: 2;
            //   grid-row-start: 1;
            //   grid-row-end: 2;
            // }
            // &:nth-child(2) {
            //   grid-column-start: 2;
            //   grid-column-end: 2;
            //   grid-row-start: 1;
            //   grid-row-end: 2;
            // }
            &:nth-child(3) {
              grid-column-start: 1;
              grid-column-end: 3;
              grid-row-start: 2;
              grid-row-end: 3;
            }
          }
        }
      }
      &.four-user {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        .user {
          &:nth-child(1) {
            grid-column-start: 1;
            grid-column-end: 2;
            grid-row-start: 1;
            grid-row-end: 2;
          }
          &:nth-child(2) {
            grid-column-start: 2;
            grid-column-end: 2;
            grid-row-start: 1;
            grid-row-end: 2;
          }
          &:nth-child(3) {
            grid-column-start: 1;
            grid-column-end: 2;
            grid-row-start: 2;
            grid-row-end: 2;
          }
          &:nth-child(4) {
            grid-column-start: 2;
            grid-column-end: 2;
            grid-row-start: 2;
            grid-row-end: 2;
          }
        }
      }
      &.five-user {
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(2, 1fr);
        .user {
          &:nth-child(1) {
            grid-column-start: 1;
            grid-column-end: 2;
            grid-row-start: 1;
            grid-row-end: 4;
          }
          &:nth-child(2) {
            grid-column-start: 2;
            grid-column-end: 3;
            grid-row-start: 1;
            grid-row-end: 4;
          }
          &:nth-child(3) {
            grid-column-start: 3;
            grid-column-end: 4;
            grid-row-start: 1;
            grid-row-end: 2;
          }
          &:nth-child(4) {
            grid-column-start: 3;
            grid-column-end: 4;
            grid-row-start: 2;
            grid-row-end: 3;
          }
          &:nth-child(5) {
            grid-column-start: 3;
            grid-column-end: 4;
            grid-row-start: 3;
            grid-row-end: 4;
          }
        }
        @media (max-width: 575.98px) {
          grid-template-columns: repeat(4, 1fr);
          grid-template-rows: repeat(4, 1fr);
          .user {
            &:nth-child(1) {
              grid-column-start: 1;
              grid-column-end: 3;
              grid-row-start: 1;
              grid-row-end: 2;
            }
            &:nth-child(2) {
              grid-column-start: 1;
              grid-column-end: 3;
              grid-row-start: 2;
              grid-row-end: 4;
            }
            &:nth-child(3) {
              grid-column-start: 3;
              grid-column-end: 5;
              grid-row-start: 1;
              grid-row-end: 2;
            }
            &:nth-child(4) {
              grid-column-start: 3;
              grid-column-end: 5;
              grid-row-start: 2;
              grid-row-end: 3;
            }
            &:nth-child(5) {
              grid-column-start: 3;
              grid-column-end: 5;
              grid-row-start: 3;
              grid-row-end: 4;
            }
          }
        }
      }
    }
  }

  .chat-messages {
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 260px);
    background-color: $home-gray;
    border-radius: $round-card;
    padding: 1rem 2rem 1rem 1rem;
    // HIDE SCROLL
    overflow-y: scroll;
    -ms-overflow-style: none !important;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
    &.no-messages {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100vh;
      //max-height: calc(100vh - 136px);
      background-color: #f0f2f5;
      border-radius: 13px;
      padding: 1rem 1rem 1rem 1rem;
      overflow-y: hidden;
      margin: 1rem 0;
    }
    @media (max-width: 767.98px) {
      max-height: calc(100vh - 65px);
      border-radius: 0;
      padding: 5rem 1rem 2rem 1rem;
    }
    .date-message {
      position: relative;
      display: flex;
      justify-content: center;
      margin-bottom: 1rem;
      &:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 1px;
        background-color: $gray-300;
        display: block;
        top: 6px;
        left: 0;
        z-index: 0;
      }
      span {
        background-color: $home-gray;
        padding: 0 1rem;
        z-index: 1;
        display: inline-block;
        position: relative;
        width: auto;
      }
    }
  }
  .call-footer {
    border-top: 1px solid $gray-300;
    height: 65px;
    position: fixed;
    width: 100%;
    background: $white;
    bottom: 0;
    left: 0;
    z-index: 100;
  }

  .icons {
    position: fixed;
    top: 80px;
    left: 0;
    width: 100%;
    background-color: #ffffff;
    z-index: 2;
    padding: 0.7rem;
  }

  p-tabview .p-tabview-panels {
    padding: 1rem 0 !important;
  }
}

// CHAT CALL PLUS
.datnek-ds-chat-call {
  z-index: 150;
}
