@import 'src/lib/ui/menus/menu';
@import 'src/lib/ui/pages/security/security';

.gap-16 {
  gap: 16px;
}
.gap-10 {
  gap: 10px;
}
.gap-8 {
  gap: 8px;
}
.gap-4 {
  gap: 4px;
}
.gap-12 {
  gap: 12px;
}
.gap-3 {
  gap: 16px;
}
.gap-2 {
  gap: 8px;
}

.mb-6 {
  margin-bottom: 4rem;
} 

a,
.link {
  color: $primary !important;
  font-size: 14px;
  &.dropdown-item {
    color: #000 !important;
    img{
      width: 20px;
    }
  }
}

.display-1 {
  font-size: 19.15px !important;
  line-height: 1;
  @media (max-width: 575.99px) {
    font-size: 18.15px !important;
    line-height: 21px;
  }
}

.app-menu .col-12 {
  padding: 0;
}

.header-body {
  .row {

  }
}

.mat-body, .mat-body-1, .mat-typography {
    font: 400 14px/20px Roboto,Helvetica Neue,sans-serif;
    letter-spacing: normal;
}

.modal-body {
    .header-body {
        .langues {
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-wrap: nowrap;
          @media (max-width: 768px) {
            justify-content: start;
            flex-wrap: wrap;
            }
            .active {
                font-weight: 600;
                color: #000;
              }
        }
    }
    .content-body {
        .nav-pills {
            width: 35%;
            gap: .5rem;
            padding: 0 1rem;
            display: flex;
            flex-direction: column;
            @media (max-width: 768px) {
              width: 100%;
              flex-direction: row !important;
              flex-wrap: wrap;
            }
            li {
              margin-bottom: .5rem;
                a {
                    &.nav-link{
                        background-color: #e8e8e8;
                        color: $dark !important;
                        width: inherit;
                        display: block;
                        padding: 0.5rem 1rem;
                        border-radius: 5px;
                        &.active {
                            color: $white !important;
                            font-weight: 500;
                            background: $primary !important;
                              background-color: transparent;
                          }
                    }
                    }
                }
        }
        .tab-content {
            flex: 1 1 auto !important;
            width: 100%;
            //padding: 0 1rem;
            .columns {
                width: 100%;
                column-count: 4;
                @media (max-width: 450.98px) {
                  column-count: 1;
                }
                @media (max-width: 575.98px) {
                  column-count: 2;
                }
                a {
                    display: block;
                  }
            }
        }
    }
}

.loading {
    height: calc(100vh - 20px);
    position: relative;
    background-color: #f5f5f5;
    font-family: Arial, Helvetica, sans-serif;
    .logo-delenscio {
        position: fixed;
        width: 100px;
        bottom: 20px;
        left: 50%;
        transform: translate(-50%, 0);
      }
      
      .anim-logo {
        width: 200px;
        height: 200px;
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(0.5);
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%, 50% 50%);
        svg {
            position: absolute;
            width: 200px;
            height: 200px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) rotate(180deg);
            .circle {
                stroke-dasharray: 1000;
                stroke-dashoffset: 1000;
                animation: stroke 2s ease-out forwards alternate-reverse infinite;
                -webkit-animation: stroke 2s ease-out forwards alternate-reverse infinite;
                animation-delay: 0.2s;
                -webkit-animation-delay: 0.2s;
              }
              .circle1 {
                stroke-dasharray: 1000;
                stroke-dashoffset: 1000;
                animation: stroke 2s ease-out forwards alternate-reverse infinite;
                -webkit-animation: stroke 2s ease-out forwards alternate-reverse infinite;
                animation-delay: 0.5s;
                -webkit-animation-delay: 0.5s;
              }
              
              .circle2 {
                stroke-dasharray: 1000;
                stroke-dashoffset: 1000;
                animation: stroke 2s ease-out forwards alternate-reverse infinite;
                -webkit-animation: stroke 2s ease-out forwards alternate-reverse infinite;
                animation-delay: 0.8s;
                -webkit-animation-delay: 0.8s;
              }
          }
      }
  }
  
@keyframes stroke {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

.title-menu::after {
  content: none;
}

datnek-ds-button-icon.active {
  .datnek-ds-button-icon--secondary {
    color: #161616;
    background-color: #c8c8c8;
  }
}

.add-member {
  display: flex;
  align-items: center;
  gap: 16px;
  .member-item {
      position: relative;
      .close{
          position: absolute;
          top: 0;
          right: 0;
          width: 20px;
          height: 20px;
          background-color: $primary;
          color: $white;
          display: flex;
          border-radius: 20px;
          font-size: 0.8rem;
          align-items: center;
          justify-content: center;
          opacity: 1;
          &:hover {
            opacity: 1;
          }
      }
  }
}

/********** SOLUTIONS *********/
@media (max-width: 768px) {
  .solutions {
    .solutions-top {
      background: transparent;
      padding-top: 5rem;
      .nav-tabs {
        .nav-link {
          span {
            font-size: 1rem !important;
            line-height: 1.1rem;
          }
        }
      }
    }
  }
}
.solutions {
  .solutions-top {
    background: $white;
    padding-top: 5rem;
    .nav-tabs {
      width: 75%;
      @media (max-width: 575.98px) {
        width: 100%;
      }
      .nav-link {
        min-height: 197px;
        @media (max-width: 460.98px) {
          padding: 0.5rem 0rem;
          height: 110px;
          min-height: 110px;
        }
        @media (min-width: 461px) and (max-width: 767.98px) {
          padding: 0.5rem 0rem;
          height: 165px;
          min-height: 125px;
        }
        @media (min-width: 768px) and (max-width: 991.98px) {
          height: 190px;
        }
        &:hover {
          border-color: transparent;
        }
        &.active {
          color: #495057;
          background-color: transparent !important;
          border-bottom: 4px solid $primary !important;
          border-color: transparent;
        }
        img {
          width: 40%;
          display: block;
          margin: 18px auto;
           @media (max-width: 576px) {
              margin: 0 auto;
            }
        }
        span {
          color: $dark;
          font-size: 1.2rem;
          font-weight: 600;
          margin-bottom: 20px;
          display: block;
          text-align: center;
          @media (max-width: 460.98px) {
            font-size: 0.7rem !important;
          }
        }
      }
      .nav-item {
        width: 25%;
      }
    }
    .solutions-content {
      nav {
        width: 100%;
      }
    }
  }
  .solutions-bottom {
    position: relative;
    overflow: hidden;
    padding: 2rem 0;
    margin-top: 0;
  z-index: 10;
  background: #f0f4f6;
    .section-content-title {
      font-size: 25px !important;
    }
    .img-solution {
      position: relative;
      z-index: 10;
      img {
        position: relative;
        z-index: 10;
        padding-top: 0;
        width: 100%;
      }
    }
  }
}

.f-800 {
  font-weight: 800;
}

// .grey {
//   position: absolute;
//   bottom: 0;
//   left: 0;
//   width: 100vw;
//   background: $dc-default;
//   height: 75px;
// }
  

.dropdown-card {
  &.card-publication-actions {
    @media (max-width:350px) {
      // width: 100vw;
      // transform: translateX(-257px);
      // position: fixed;
      // top: 0;
      // left: 0;
      max-height: calc(100vh - 0px);
      overflow-y: auto;
    }
    .close {
      display: none;
      @media (max-width:350px) {
        display: flex;
      }
    }
  }
} 

.card-user-box {
  &.user-box {
    @media (max-width:575.98px) {
        border-radius: 0 !important;
      }
    .header {
      @media (max-width:575.98px) {
        height: 70px !important;
        border-radius: 0 !important;
      }
      datnek-ds-button-icon {
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 1;
      }
      img {
        width: inherit;
        height: inherit;
        object-fit: cover;
      }
    }
  .content {
    .stats {
      @media (max-width:575.98px) {
        padding: 1.5rem 0rem;
        border-top: 1px solid #e9e7e7;
        display: flex;
      }
      .datas {
        @media (max-width:575.98px) {
          width: 100%;
          padding: 0 !important;
          border-bottom: 0px solid #dee2e6 !important;
        }
        
      }
    }
      
    }
  }
}

/********* DROPDOWNS *********/
.dropdown-dispo {
  width: 222px;
  padding: 1rem 1rem 0;
}
@media (max-width: 767.98px) {
  .datnek-ds-card {
    &.edit-menu {
      transform: translateX(-97px);
      border-radius: 13px !important;
      &.placement {
        transform: translateX(-135px);
      }
    }
  }
}

@media (max-width: 1199.98px) {
  .datnek-ds-card {
    &.edit-menu {
      transform: translateX(-97px);
      border-radius: 13px !important;
      &.placement {
        transform: translateX(-135px);
      }
    }
  }
}


@media (max-width: 576px) {
  .datnek-ds-card {
    margin-bottom: 0 !important;   
    &.mb-3 {
      margin-bottom: 1rem !important;
    }
  } 
}

.dialog-new-content {
  .thumbnail-view {
    width: 100%;
    .items {
      width: 100%;
      .item-list {
        display: grid;
        gap: 1rem;
        grid-template-columns: repeat(5,1fr);
        @media (max-width: 576px) {
          grid-template-columns: repeat(2,1fr);
        }

        @media (max-width: 767.98px) {
          grid-template-columns: repeat(3,1fr);
        }
        .img {
          position: relative;
          width: 100%;
          height: 120px;
          margin-left: 0;
          margin-right: 0;
          img {
            width: inherit;
            height: inherit;
            object-fit: cover;
            border-radius: 6px;
            overflow: hidden;
          }
          .close-btn {
            position: absolute;
            top: 6px;
            right: 6px;
          }
        }
        figure {
          width: 100%;
          height: 125px;
          margin-bottom: 0;
          position: relative;
          overflow: hidden;
          border-radius: 6px;
          .play {
              width: 40px;
              position: absolute;
              z-index: 2;
              img {
                  height: auto
              }
          }
          &:before {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            display: block;
            background-color: #16161680;
            z-index: 1;
            border-radius: 6px
          }
          video {
              width: 100%;
              height: 100%;
              transform: scale(1.8)
          }
        }
        &.link-list {
            grid-template-columns: repeat(3,1fr);
            @media (max-width: 576px) {
              grid-template-columns: repeat(2,1fr);
            }

            @media (max-width: 767.98px) {
              grid-template-columns: repeat(3,1fr);
            }
        }
        .link {
            border-radius: 13px;
            overflow: hidden;
            .img {
                width: 100%;
                height: 100px;
                margin: 0;
                img {
                    width: inherit;
                    height: inherit;
                    object-fit: cover;
                    border-radius: 0;
                }

            }

        }

      }
    }
    .icon-close {
      position: relative;
      top: -10px;
    }
  }
  .icons, .icons .icon-content {
    width: 100%;
  }
  .text-content {
    .text-format {
      outline: 0;
      //min-height: 100px;
      height: 135px !important;
      border: 0;
      text-overflow: ellipsis;
      cursor: text;
      margin-bottom: 0;
      background-color: initial;
      padding: 0;
      flex-shrink: 1;
      flex-basis: auto;
      flex-grow: 1;
      overflow-y: scroll;
      overflow-x: hidden;
      -ms-overflow-style: none!important;
      scrollbar-width: none;
      &[contentEditable=true]:empty:not(:focus):before {
          content: attr(data-placeholder);
          color: #b9b9b9
      }
      &[contentEditable=true]:focus {
          margin-bottom: 0
      }
      &::-webkit-scrollbar {
          display: none
      }
      &:focus, &:focus-visible , &:focus-within{
        border: 0!important;
        outline: 0;
      }
    }
    .icons network-button-icon, .icons datnek-ds-button-icon {
        width: 38px
    }
  }
  .editor-icons {
    border: 1px solid #b9b9b9;
    padding: 1rem;
    position: sticky;
    top: -2px;
    background: #fff;
    border-radius: 0;
    margin-left: -16px;
    margin-right: -16px;
    margin-top: -17px;
    img {
      width: 16px;
      margin-right: 1rem;
      cursor: pointer;
      &:hover {
          opacity: .8
      }
    }
  }
  .content-modal {
    height: 300px;
    overflow-y: scroll;
    -ms-overflow-style: none!important;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none
    }
    @media (max-width: 768px) {
      height:calc(100vh - 117px)
    }
  }
}

#button {
    position: relative;
}

.editor-icons {
    border: 1px solid #b9b9b9;
    padding: 1rem;
    position: sticky;
    top: -2px;
    background: $white;
    border-radius: 0;
    margin-left: -16px;
    margin-right: -16px;
    margin-top: -17px;
    img {
      width: 16px;
      margin-right: 1rem;
      cursor: pointer;
    }
  }

.suggestion-pitch {
  .suggestion-header {
    .network-user-infos-small {
      .infos {
        img {
          transform: scale(.4);
        }
      }
    }
  }
  .datnek-ds-card-likes-buttons {
    .like-btn {
      position: relative;
      opacity: 1;
      flex: 1;
    }
  }
  .buttons-icons {
    .share-post.dropdown, button, network-button, datnek-ds-button {
      flex: 1;
    }
  }
}

.clip {
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: clip;
  //padding-bottom: 2px;
  &.normal, &.initial {
      white-space: normal;
    }
  @media (max-width: 575.98px) {
    //white-space: initial;
    &.nowrap {
      white-space: nowrap;
    }
    &.normal, &.initial {
      white-space: normal;
    }
  }
  
}
.ellipsis {
  text-overflow: ellipsis;
}
.truncate_1 {
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box !important;
}
.truncate_2 {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box !important;
}
.truncate_3 {
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box !important;
}
.truncated {
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    padding: .4rem 0;
}

.images-list {
  &.style2 {
    @media (max-width: 576px) {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}

.datnek-ds-dialog {
  .datnek-ds-dialog-footer {
    z-index: 1;
    bottom: -1px !important;
  }
}

.datnek-ds-dialog-custorm {
  .datnek-ds-dialog-body {
    &:hover {
      .datnek-ds-dialog-footer {
        display: flex !important;
        z-index: 10000;
      }
      // &::after {
      //   height: 50px;
      //   @media (max-width: 575.98px) {
      //     height: 0 !important;
      //   }
      // }
      // .icon-close {
      //   display: block;
      // }
    }
  }
}

.d-grid {
  display: grid;
  gap: 1rem;
  &-auto {
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  }
  &-style1 {
    grid-template-columns: repeat(auto-fit, minmax(145px, 1fr));
  }
  &-style2 {
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  }
}

.datnek-ds-button--link:hover {
  background-color: #e0efff;
}

.embed-responsive {
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    overflow: hidden;
}

.editor-chat-popup-box {
  &.editor-add-comments {
    padding: 0 16px 0 6px;
  }
  .editor-add-comment.comment-text .text-content {

  }
}

.comments-view {
  .icon-close {
    position: absolute;
    top: 10px;
    right: 10px;
    display: block;
    opacity: 1;
    &.no-comment {
      display: none;
    }
  }
}

datnek-ds-editor-chat-popup-box {
  width: 100%;
}

.user-chat-box {
  .user-chat-list {
    &> div {
      width: calc(100% - 45px) !important;
    }
    .infos {
      width: calc(100% - 30px) !important;
    }
    .time {
      width: calc(100% - 25px);
    }
  }
}

.list-popup-box {
  width: calc(100% - 16px);
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  margin: 0;
}

.big-text {
    margin-bottom: 43px;
}

.head-title-with-icon {
  width: calc(100% - 50px);
}

.head-title-without-icon {
  width: calc(100% - 10px);
}

.datnek-ds-upload {
  margin-bottom: 1rem;
}

.form-group.mb-3 {
  margin-bottom: 1rem !important;
}

.datnek-ds-select2 {
  .text-editor {
    margin-bottom: 0 !important;
  }
}

.p-sidebar .p-sidebar-content {
  padding: 0 !important;
}

// SUGGESTION CARD MOBILE ============================================
.suggestion-card-mobile {
  .video {
    height: auto; //150px
    background-color: #212121;
    position: relative;
    overflow: hidden;
    border-radius: 13px 13px 0 0;
    .play {
      position: absolute;
      top: calc(50% - 25px);
      left: calc(50% - 25px);
      z-index: 1;
    }
    video {
      width: 100% !important;
      height: auto;
    }
  }
}

// SUGGESTION LIST ------------------------------------------------------
.suggestion-list {
  &.nk-shared-user-infos-small {
    transition: all ease-in 0.15s;
    &:hover {
      text-decoration: none;
      background-color: $home-gray;
      // border-radius: $round-card;
    }
    .infos {
      width: calc(100% - 100px); // 100%
      
      &:hover {
        text-decoration: none;
      }
      .name {
        color: $text;
        position: relative;
        width: auto;
        & > div {
          display: inline;
          width: min(100%, 100%);
        }
        img {
          position: relative;
          right: 10px;
          margin-top: 4px;
        }
      }
      span {
        color: $gray-300;
        font-size: 13px !important;
        line-height: 14px;
        &.details {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          gap: 5px;
          span {
            width: min(40%, 180px);
            @media (max-width: 575.98px) {
              width: min(40%, 100px);
            }
          }
        }
      }
    }
    .datnek-ds-avatar--medium {
      height: 45px;
      width: $img-tiny;
    }
  }

  .roles {
    margin-top: -20px;
    position: relative;
    right: -5px;
  }

  .dropdown-menu.show {
    width: 220px !important;
    transform: translate(-175px, 60px) !important;
  }
}

.nk-shared-user-infos-small {
  .suggestion-infos {
    width: calc(100% - 35px);
  }
}

// SUGGESTION FULL LIST ------------------------------------------------------
.suggestion-full-list {
  &.suggestion-container {
    margin: 0 auto;
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
    @media (min-width: 768.98px) {
      //grid-template-columns: repeat(3, 1fr);
    }
  }
}

// Show-Gallery --------------------------------------------------------------
.show-gallery {
  &.gallery-publication-lightbox {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    margin-top: 0;
    background-color: rgba(0, 0, 0, 0.85);
    z-index: 100;
    @media (max-width: 767.98px) {
      flex-direction: column;
    }
    .lightbox-view {
      height: 100vh;
      width: 100%;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all ease-in 0.05s;
      @media (max-width: 767.98px) {
        width: 100%;
        height: 50vh !important;
        &.no-comment {
          height: 100vh !important;
        }
      }
      &.no-comment {
        width: 100% !important;
      }
      &:hover {
        datnek-ds-button-icon {
          display: block;
          opacity: 1;
        }
      }
      .count {
        position: fixed;
        bottom: 16px;
        //right: 16px;
        color: $text;
        opacity: 1;
        font-size: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 2;
        background: rgba($gray-300, 70%);
        padding: 0.4rem 1rem;
        border-radius: 20px;
        @media (max-width: 767.98px) {
          position: absolute;
          font-size: 14px;
        }
      }
      .close-btn {
        position: fixed;
        top: 16px;
        left: 16px;
        padding: 0;
        z-index: 2;
      }
      .zoomin-btn {
        position: absolute;
        top: 16px;
        right: 20px;
        padding: 0;
        z-index: 2;
        cursor: pointer;
      }
      .zoomout-btn {
        position: absolute;
        top: 16px;
        right: 70px;
        padding: 0;
        z-index: 2;
        cursor: pointer;
      }
      .favorite-btn {
        position: absolute;
        top: 16px;
        right: 125px;
        padding: 0;
        z-index: 2;
        cursor: pointer;
      }
      .lightbox-img {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        height: auto;
        width: auto;
        h5 {
          position: absolute;
          bottom: 20px;
        }
        img {
          width: 80%;
          height: -webkit-fill-available;
          border-radius: 0.35rem;
        }
      }
      .lightbox-carousel {
        &.btn-prev,
        &.btn-next {
          position: absolute;
          top: 50%;
          z-index: 25;
          @media (max-width: 767.98px) {
            position: absolute;
          }
        }
        &.btn-prev {
          left: 16px;
        }
        &.btn-next {
          right: 16px;
          @media (max-width: 767.98px) {
            right: 16px;
          }
        }
      }
    }
    .comments-view {
      height: 100vh;
      width: 30%;
      position: relative;
      @media (max-width: 767.98px) {
        width: 100%;
        height: 50vh;
        &.no-comment {
          height: 0;
        }
      }
      &.no-comment {
        width: 0;
      }
    }
    
  }
  &.stories {
    .lightbox-view {
      @media (max-width: 767.98px) {
        height: 100vh;
      }
      .story-head {
        right: 16px;
        width: 100%;
        position: absolute;
        top: 16px;
      }
      .lightbox-img {
        img {
          width: 100% !important;
          height: 80vh;
          display: block;
          border-radius: 0 !important;
          @media (max-width: 576px) {
            height: auto;
          }
        }
      }
      .option-btn {
        position: relative;
        // top: 16px;
        // right: 16px;
        // padding: 0;
        z-index: 2;
      }
      .bottom-stories {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 10;
        width: 100%;
        padding: 1rem;
        background: rgb(0,0,0);
        background: -moz-linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
        background: -webkit-linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
        background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
        .s-top {
          width: 100%;
          height: 100px;
          margin-bottom: 2rem;
          vertical-align: bottom;
          display: flex;
          align-items: end;
          //background-color: red;
          .titre {
            color: $white;
            font-size: 25px;
            font-weight: 600;
          }
        }
        .s-bottom {
          width: 100%;
          height: 50px;
          //background-color: #fff;
          .left {
            width: 50%;
          }
          .right {
            width: 50%;
            display: flex;
            justify-content: end;
            gap: 1rem;
            .btn-gallery {
              position: relative;
              z-index: 2;
              cursor: pointer;
            }
          }
        }
        
      }
      datnek-ds-button-icon {
        display: block;
        opacity: 1;
      }
    }
  }
  datnek-ds-button-icon {
    display: none;
    opacity: 0;
  }
}

// USER CHAT LIST ==============================================
.user-chat-box {
  padding-top: 3rem;
  &.pinned {
    padding-top: 0;
  }
  .search-input {
    position: absolute;
    width: 94%;
    top: 0;
    z-index: 10;
  }
}

// SUGGESTION BOX
.invitation-box {
  .suggestion-content {
    max-height: 400px;
  }
}

// FRIENDS =====================================================
.friends {
  .suggestion-box {
    .suggestion-content {
      max-height: unset !important;
    }
  }
}

// EVENTS ------------------------------------------------
.events {
  &.content {
    padding-top: 5rem;
    ul.sidebar-menu {
      padding: 0;
      list-style: none;
      li {
        padding: 8px;
        transition: all ease-in 0.15s;
        &:hover {
          border-radius: $round-card;
          background-color: $home-gray;
        }
        a {
          text-decoration: none;
        }
      }
    }

    .header {
      height: 70px;
      border-bottom: 1px solid $gray-slate-300;
    }
  }

  .calendar-header {
    height: 52px;
    border-bottom: 1px solid $gray-300;
    .bordered-button {
      border-radius: $round;
      border: 2px solid $gray-slate-300;
      &:hover {
        border: 2px solid $primary;
      }
    }
    .left {
      width: 74%;
    }
    .right {
      height: 34px;
      width: 26%;
    }
    .datnek-border-dorpdown {
      border-radius: $round;
      border: 2px solid $gray-slate-300;
      color: $gray-slate-300;
      height: inherit;
      display: flex;
      align-items: center;
    }
  }

  .sidebar-event {
    max-height: calc(100vh - 90px);
    overflow-y: scroll;
    // padding-top: 12px;
    -ms-overflow-style: none !important;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  nk-shared-card-user-box {
    position: relative;
    top: 0;
  }

  .p-tabview .p-tabview-panels {
    background: #ffffff;
    padding: 0;
    border: 0 none;
    color: #495057;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
  }
  .cal-cell-row.cal-header {
    font-weight: 400 !important;
  }
  .cal-week-view .cal-time {
    font-weight: 400 !important;
  }

  .cal-week-view {
    font-weight: 400 !important;
    b {
      font-weight: 400 !important;
    }
  }

  .cal-month-view {
    .cal-open-day-events {
      background-color: $gray-slate-200;
      box-shadow: none;
    }
    .cal-event-title {
      color: $text;
    }
    .cal-day-badge {
      background-color: $primary;
    }
    .cal-day-cell.cal-weekend .cal-day-number {
      color: $primary;
    }
  }

  @media (max-width: 991.98px) {
    .p-scrollpanel.p-component {
      height: calc(100vh - 807px) !important;
    }
  }
  @media (min-width: 992px) {
    .p-scrollpanel.p-component {
      height: calc(100vh - 135px) !important;
    }
  }
}

// PAGE EN CONSTRUCTION ====================================
.in-progress {
  width: 100vw;
  height: 100vh;
  background: $white;
  //padding-top: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .image {
    width: 50%;
    @media (max-width: 575.98px) {
      width: 100%;
    }
    img {
      width: 100%;
    }
  }
}

// ITEM CONTENT ===========================================
.item-content {
  padding: 1rem;
  gap: 12px;
  position: relative;
  &.network {
    padding: 1rem 0;
  }
  .date {
    background: #eeee;
    width: fit-content;
    color: #000;
    font-size: 13px;
    padding: 0.3rem 0.5rem;
    font-weight: 500;
    border-radius: 5px;
  }
  h4 {
    font-weight: 600 !important;
    font-size: 17px !important;
    color: #000 !important;
    letter-spacing: 0;
    @media (max-width: 575.98px) {
      font-size: 17px !important;
    }
  }
  h5 {
    font-weight: 600 !important;
    font-size: 17px !important;
    color: #000 !important;
    @media (max-width: 575.98px) {
      font-size: 16.5px !important;
    }
  }
  .accordion-btn {
    width: 33px;
    height: 33px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #e0efff;
    border-radius: 50%;
    position: absolute;
    bottom: 16px;
    right: 16px;
  }
  .icon-dropdown {
    position: absolute;
    top: 16px;
    right: 16px;
    z-index: 100;
  }
  .item {
    width: 92%;
    @media (max-width: 575.98px) {
      width: 100% !important;
    }
    .infos {
      @media (max-width: 575.98px) {
        margin-left: -1rem !important;
        margin-right: -1rem !important;
        flex-direction: column;
      }
      .img {
        width: 131px;
        height: 123px;
        position: relative;
        @media (max-width: 575.98px) {
          width: 100%;
          height: 125px;
        }
        img {
          width: inherit;
          height: inherit;
          object-fit: cover;
        }
        .icon {
          position: absolute;
          top: 50%;
          left: 50%;
          width: 24px;
          height: 24px;
        }
      }
    }
  }
  &.certification {
    overflow: hidden;
    border-radius: 13px;
    @media (max-width: 575.98px) {
         border-radius: 0;
        }
    .icon-dropdown {
      position: absolute;
      top: 10px;
      right: 10px;
      z-index: 100;
    }
    .item {
      .infos {
        flex-direction: row !important;
        @media (max-width: 575.98px) {
          margin-left: unset !important;
          margin-right: unset !important;
          flex-direction: column !important;
        }
        .img {
          width: 155px;
          height: 160px;
          position: relative;
          @media (max-width: 575.98px) {
            width: 100%;
          }
          &::after {
            content: '';
            display: block;
            width: 100%;
            height: 100%;
            background-color: rgba(245, 207, 135, 0.3);
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
          }
          .icon {
            position: absolute;
            top: calc(50% - 12px);
            left: calc(50% - 12px);
            width: 24px;
            height: 24px;
            z-index: 2;
          }
        }
        .details {
          display: flex;
          flex-direction: column;
          justify-content: center;
          gap: 6px;
          padding: 0 1rem;
          &.history {
            .date {
              width: 20%;
            }
            .description {
              width: 70%;
            }
            .prix {
              width: 30%;
            }
          }
          h4 {
            width: 25rem;
            @media (max-width: 575.98px) {
              width: 100%;
            }
          }
        }
      }
    }
  }
  &.langues {
    .item {
      @media (max-width: 575.98px) {
        flex-direction: column;
        width: 100%;
      }
      .infos {
        @media (max-width: 575.98px) {
          margin-left: 0 !important;
          margin-right: 0 !important;
          flex-direction: column;
        }
      }
    }
    span {
      font-size: 14px;
    }
    .chart {
      position: relative;
      margin-bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 70px;
      transform: scale(0.7);
      svg {
        width: 70px;
        height: 70px;
        circle {
          fill: none;
          stroke-width: 3px;
          transform: translate(5px, 5px);
          stroke-dasharray: 190;
          &.law {
            stroke: #af502b;
          }
          &.hight {
            stroke: $primary;
          }
          &.intermediate {
            stroke: #f5cf87;
          }
        }
      }
      span {
        position: absolute;
        font-weight: 500;
        left: 50%;
        transform: translateX(-50%);
        font-size: 19px;
      }
    }
  }
  &.recommandation {
    .img {
      width: 105px;
      height: 105px;
      cursor: pointer;
      background-color: #f0f2f5;
      border-radius: 5px;
      text-align: center;
      position: relative;
      datnek-ds-button-icon {
        position: absolute;
        transform: translateX(-50%) translateY(-50%);
        left: 50%;
        top: 50%;
      }
      img {
        height: 100%;
        margin: auto;
        padding: 0 1rem;
      }
    }
    .item {
      @media (max-width: 768.98px) {
        flex-direction: column;
      }
    }
    .infos {
      @media (max-width: 768.98px) {
        padding: 16px;
      }
      
    }
  }
  &.exp-pro {
    .item {
      .infos {
        @media (max-width: 767.9px) {
          padding: 0 1rem;
        }
      }
    }
  }
  &.competences {
    //margin-right: 35px;
    .content-infos {
      margin-right: 35px;
    }
    .items {
      margin-right: 35px;
      @media (max-width: 575.98px) {
        flex-direction: column;
        width: 92%;
      }
      .infos {
        @media (max-width: 767.98px) {
          //padding: 0;
        }
      }
    }
  }
  &.formation {
    .item {
      .infos {
        @media (max-width: 575.98px) {
          margin-left: 0 !important;
          margin-right: 0 !important;
          flex-direction: column;
        }
      }
    }
  }
  &.projet {
    .item {
      .infos {
        @media (max-width: 575.98px) {
          margin-left: 0 !important;
          margin-right: 0 !important;
          flex-direction: column;
        }
      }
    }
  }
  &.event {
    .img {
      width: 175px !important;
      height: 120px;
      //flex: 1;
      @media (max-width: 575.98px) {
          width: 100vw !important;
          margin-left: -16px;
          margin-top: -16px;
        }
      img {
        object-fit: cover;
        height: inherit;
        width: inherit;
      }
    }
    .item {
        width: calc(100% - 175px);
    }
    .event-item {
      display: flex;
      @media (max-width: 575.98px) {
        flex-direction: column;
      }
      .infos-2 {
        @media (max-width: 575.98px) {
          flex-direction: column;
        }
      }
      .buttons {
        @media (max-width: 575.98px) {
          flex-wrap: wrap;
        }
      }
      
    }
    &.network {
      .img {
        width: 100px !important;
        height: 100px;
        //flex: 1;
        @media (max-width: 575.98px) {
            width: 100vw !important;
            margin-left: 0px;
            margin-top: -16px;
          }
        img {
          object-fit: cover;
          height: inherit;
          width: inherit;
        }
      }
      &.event-id {
        .img {
          width: 100% !important;
          height: 300px;
          @media (max-width: 575.98px) {
            width: 100vw !important;
            margin-left: 0 !important;
          }
        }
        &.users {
          flex-wrap: wrap !important;
          @media (max-width: 575.98px) {
              flex-wrap: wrap;
            }
          .events-users {
            span {
              font-weight: 500;
              color: #989898;
              font-size: 16px;
              font-style: italic;
            }
          }
          .user-box {
            width: 100%;
            margin-bottom: 1rem;
            @media (max-width: 765.98px) {
              width: 100% !important;
              margin-bottom: 0;
            }
            .name {
              span {
                width: auto;
                max-width: min(84%, 370px);
                display: inline-block;
              }
              img {
                width: 13px;
                height: 13px;
                margin-top: 5px;
              }
            }
          }
          .actions {
            width: 100%;
            justify-content: start;
            
            @media (max-width: 575.98px) {
              width: 100% !important;
              flex-wrap: wrap;
            }
            button {
              font-weight: 600;
              padding: 0.4rem 1rem;
              border-radius: 4px;
              transition: all ease-in 0.5s;
              &:hover {
                &.contact {
                  background-color: rgba($primary, 0.1);
                }
                &.confirm {
                  background-color: rgba($success, 0.1);
                }
                &.confirm-wait {
                  background-color: rgba($warning, 0.1);
                }
                &.cancel {
                  background-color: rgba($danger, 0.1);
                }
              }
            }
            .contact {
              color: $primary;
            }
            .confirm {
              color: $success; //#54ba78
            }
            .confirm-wait {
              color: $warning; //#e3bc74
            }
            .cancel {
              color: $danger; //#cf2323
            }
          }
          h3 {
            font-size: 19px !important;
          }
          h4 {
            font-size: 17px !important;
          }
        }
        .dapartement {
          border-radius: 10px;
          .infos {
            width: 60%;
          }
          .persons {
            width: 25px;
          }

          &.level1 {
            background: #e0efff;
            border-left: 3px solid $primary;
          }

          &.level2 {
            background: #fff3dd;
            border-left: 3px solid $primary;
          }
        }
        .item {
          width: 100%;
      }
      }
    }

    &.event-id {
      .img {
        width: 100% !important;
        height: 300px;
        @media (max-width: 575.98px) {
          width: 100vw !important;
          margin-left: 0 !important;
        }
      }
      &.users {
        flex-wrap: wrap !important;
        @media (max-width: 575.98px) {
            flex-wrap: wrap;
          }
        .events-users {
          span {
            font-weight: 500;
            color: #989898;
            font-size: 16px;
            font-style: italic;
          }
        }
        .user-box {
          width: 50%;
          margin-bottom: 1rem;
          @media (max-width: 765.98px) {
            width: 100% !important;
            margin-bottom: 0;
          }
          .name {
            span {
              width: auto;
              max-width: min(84%, 370px);
              display: inline-block;
            }
            img {
              width: 13px;
              height: 13px;
              margin-top: 5px;
            }
          }
        }
        .actions {
          width: 50%;
          justify-content: end;
          
          @media (max-width: 575.98px) {
            width: 100% !important;
            flex-wrap: wrap;
          }
          button {
            font-weight: 600;
            padding: 0.4rem 1rem;
            border-radius: 4px;
            transition: all ease-in 0.5s;
            &:hover {
              &.contact {
                background-color: rgba($primary, 0.1);
              }
              &.confirm {
                background-color: rgba($success, 0.1);
              }
              &.confirm-wait {
                background-color: rgba($warning, 0.1);
              }
              &.cancel {
                background-color: rgba($danger, 0.1);
              }
            }
          }
          .contact {
            color: $primary;
          }
          .confirm {
            color: $success; //#54ba78
          }
          .confirm-wait {
            color: $warning; //#e3bc74
          }
          .cancel {
            color: $danger; //#cf2323
          }
        }
        h3 {
          font-size: 19px !important;
        }
        h4 {
          font-size: 17px !important;
        }
      }
      .dapartement {
        border-radius: 10px;
        .infos {
          width: 60%;
        }
        .persons {
          width: 25px;
        }

        &.level1 {
          background: #e0efff;
          border-left: 3px solid $primary;
        }

        &.level2 {
          background: #fff3dd;
          border-left: 3px solid $primary;
        }
      }
      .item {
        width: 100%;
    }
    }
  }
  &.candidature {
    .img {
      width: 120px;
      @media (max-width: 575.98px) {
        width: 70px;
      }
      img {
        object-fit: cover;
      }
    }
    .item {
      @media (max-width: 767.98px) {
        flex-wrap: wrap;
      }
      
    }
    .chart svg circle {
      stroke-width: 5px;
      stroke: #1cae58;
    }
    .final-score {
      @media (max-width: 767.98px) {
        width: 100%;
      }
      
    }
    .score {
      width: 50%;
      @media (max-width: 767.98px) {
        width: 100%;
      }
    }
    .progress {
      display: flex;
      height: 0.6rem;
      overflow: hidden;
      line-height: 0;
      font-size: 0.75rem;
      background-color: #e9ecef;
      width: 151px;
      border-radius: 0.25rem;
      .progress-bar {
        background-color: #1cae58;
        &.results {
          background-color: #117139;
        }
      }
      @media (max-width: 767.98px) {
        width: 100%;
      }
    }
    
    .infos {
      margin-left: unset !important;
      margin-right: unset !important;
    }
  }
  &.job {
    .items-stats {
      flex-wrap: wrap;
      
    }
  }
}

//======================== FORMLY =========================//
.content-text {
  // border: 1px solid #ccc;
  // border-radius: 10px;
  overflow: hidden;
  &.editor-add-article {
    .editor-icons {
      border-top: 0px solid #b9b9b9;
    }
    .text-format {
      padding: 12px 0;
    }
  }
}

//======================== EVENTS ===========================
.user-list-grid {
  he-shared-user-box-simple, nk-shared-card-user-box-simple {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    @media (max-width: 768px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}

.icon-synchro {
  flex-wrap: wrap;
  gap: 1rem;
  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 23%;
    img {
      width: 60px;
      margin: auto !important;
      padding-bottom: 1rem;
      span {
        margin-top: 1rem;
      }
    }
    @media (max-width: 575.98px) {
      width: 45%;
      img {
        width: 70px;
        
      }
    }

    @media (min-width: 575.98px) and (max-width: 768px) {
      width: 45%;
      img {
        width: 78px;
        margin: auto !important;
         span {
          margin-top: 1rem;
        }
      }
    }
  }
}

.import-contact {
  input {
    opacity: 0;
  }
  img {
    width: 50px;
  }
}

.events {
  .header {
    h3 {
      width: 30%;
      @media (max-width: 575.98px) {
        width: 50%;
      }
    }
  }
}

.cdk-overlay-container {
    z-index: 10000;
}

.progress-bar {
  background-color: $primary !important;
}

// SLIDE SOLITIONS ========================================
.content-solutions {
  img {
    width: 100%;
    display: none;
    width: 80%;
    margin: auto;
    &.img-active {
      display: block;
    }
  }
  .solution-item {
    padding: 2rem 3rem;
    cursor: pointer;
    .title{
      
    }
    p {
      font-size: 1.125rem;
      line-height: 1.75rem;
      letter-spacing: 0rem;
      color: #5f6368;
      display: none;
    }
    &.active {
      background-color: #fff;
      p {
        display: block;
      }
    }
  }
}

.fade {
  animation-name: fade;
  animation-duration: 1.5s;
}

@keyframes fade {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}

// FOOTER -------------------------------------------------
footer {
    padding: 2rem 2rem 0 2rem;
    position: relative;
    h4 {
      font-size: 1.2rem;
    }
    ul {
      padding: 0;
      list-style: none;
      a {
        color: $text;
      }
    }
    &.footer-wrap {
      background-color: #f0f4f6;
      position: relative;
      z-index: 1;
      @media (max-width: 575.98px) {
        background-color: #fff;
      }
      &:before {
        content: assetUrl('Img/homepage/footer_shape.png');
        position: absolute;
        bottom: 0;
        left: 50%;
        -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        transform: translateX(-50%);
        z-index: -1;
        overflow: hidden;
        line-height: 0;
      }
      &.no-topbg {
        &:before {
          content: none !important;
        }
      }
      .footer-top-image {
        margin-bottom: 5px;
        li {

          display: inline-block;
          position: absolute;
          top: -103px;
          -webkit-transform: translateX(-50%);
          -ms-transform: translateX(-50%);
          transform: translateX(-50%);
          z-index: -2;
          &:nth-child(1n) {
            left: 50%;
          }
          &:nth-child(2n) {
            left: calc(50% + 135px);
            top: -100px;
          }
          &:nth-child(3n) {
            left: calc(50% - 135px);
            top: -97px;
          }
          &:nth-child(4n) {
            left: calc(50% + 270px);
            top: -97px;
          }
          &:nth-child(5n) {
            left: calc(50% - 270px);
            top: -88px;
          }
          &:nth-child(6n) {
            left: calc(50% + 405px);
            top: -80px;
          }
          &:nth-child(7n) {
            left: calc(50% - 405px);
            top: -69px;
          }
          &:nth-child(8n) {
            left: calc(50% + 540px);
            top: -60px;
          }
          &:nth-child(9n) {
            left: calc(50% - 540px);
            top: -44px;
          }
        }
      }
    }

    @media only screen and (max-width: 479px) {
      .footer-wrap .footer-top-image {
        display: none;
      }
    }

    @media only screen and (max-width: 767px) {
      .footer-wrap .footer-top-image li:nth-child(4n) {
        display: none;
      }
    }

    @media only screen and (max-width: 767px) {
      .footer-wrap .footer-top-image li:nth-child(5n) {
        display: none;
      }
    }

    @media only screen and (max-width: 991px) {
      .footer-wrap .footer-top-image li:nth-child(6n) {
        display: none;
      }
    }

    @media only screen and (max-width: 991px) {
      .footer-wrap .footer-top-image li:nth-child(7n) {
        display: none;
      }
    }

    @media only screen and (max-width: 1199px) {
      .footer-wrap .footer-top-image li:nth-child(8n) {
        display: none;
      }
    }

    @media only screen and (max-width: 1199px) {
      .footer-wrap .footer-top-image li:nth-child(9n) {
        display: none;
      }
    }

    .main-footer {
    padding: 140px 0 25px;
    @media only screen and (max-width: 991px) {
      background-color: #ffffff;
      padding: 80px 0 25px;
    }
    @media (max-width: 575.98px) {
      padding: 12px 0 25px;
    }
  }

  .footer-box {
    margin-bottom: 50px;
    @media (max-width: 575.98px) {
      margin-bottom: 25px;
    }
    .footer-logo {
      margin-bottom: 20px;
    }
    .footer-title {
      font-size: 22px;
      padding-bottom: 18px;
      margin-bottom: 22px;
      position: relative;
      @media (max-width: 575.98px) {
        padding-bottom: 5px;
        margin-bottom: 22px !important;
      }
      &:after {
        content: '';
        height: 3px;
        width: 20px;
        background-color: $primary;
        position: absolute;
        bottom: 0;
        left: 8px;
      }
      &:before {
        content: '';
        height: 3px;
        width: 6px;
        background-color: $primary;
        position: absolute;
        bottom: 0;
        left: 0;
      }
    }
    .footer-link ul li {
      margin-bottom: 7px;
      a {
        color: #646464 !important;
        &:hover {
          color: $primary;
        }
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .footer-bottom {
    padding: 10px 0;
    border-top: 1px solid #ececec;
    text-align: center;
    .footer-copyright {
      color: #838383;
    }
  }
  }

