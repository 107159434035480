/* DS IMPORTS */
@import 'node_modules/datnek-ds/assets/scss/network/colors';
@import 'node_modules/datnek-ds/assets/scss/network/constants';
@import 'node_modules/datnek-ds/assets/scss/network/container-grid';
@import '../apps/network/delenscio-network/src/environments/scss/environment.scss';
/*  END IMPORTS */

@import 'libs/libs-network';
@import '../apps/network/delenscio-network/src/styles';

html {
  overflow-x: hidden;
  body {
    overflow-x: inherit;
  }
}
