@import "network-challenge/src/lib/challenge";
@import "network-chat/src/lib/chat";
@import "network-company/src/lib/enterprise";
@import "network-group/src/lib/groups";
@import "network-request/src/lib/request";
@import "network-shared/src/lib/lib";

.contenu {
    line-height: 1.1rem;
  }

formly-wrapper-form-field div {
    top: 4px;
    position: relative;
}
