.sticky-box {
  height: 650px;
  .sticky-item {
    position: sticky;
    bottom: 15px;
  }
}

.gap-16 {
  gap: 16px;
}

// ADD ARTICLE ------------------------------------------------

.add-article {
  padding-top: 5rem;
  min-height: calc(100vh - 3px);
  margin-bottom: 0;
  .header {
    padding: 0rem 1rem;
    height: 58px;
    border-bottom: 1px solid $gray-slate-300;
    .user {
      width: 50%;
    }
    .actions {
      width: 50%;
      gap: 16px;
      @media (max-width: 575.98px) {
        width: 20%;
      }
    }
  }
  .datnek-ds-card {
    @media (min-width: 768px) and (max-width: 991.98px) {
      min-height: calc(100vh - 80px);
    }
    @media (max-width: 767.98px) {
      min-height: calc(100vh - 80px);
    }
  }
  .content {
    position: relative;
    z-index: 0;
  }
  .title-text {
    input,
    textarea {
      width: 100%;
      max-height: auto;
      border: 0;
      outline: 0;
      color: $text;
      &:focus-visible,
      &:focus-within,
      &:focus {
        border: 0 !important;
        outline: 0;
      }
      &.big-text {
        font-size: 3rem;
        font-weight: 500;
        line-height: normal;
        resize: initial;
        padding: 0;
        margin-bottom: 11px;
        @media (max-width: 575.98px) {
          font-size: 2rem;
        }
      }
      &::placeholder {
        color: $gray-slate-300;
      }
    }
  }
  .float-add-button,
  .float-add-button-1 {
    position: fixed;
    bottom: 68px;
    right: 16px;
    z-index: 10;
    width: 50px;
    height: 50px;
    border-radius: $round-full;
    background-color: $white;
    cursor: pointer;
    datnek-ds-button-icon {
      position: absolute;
      top: 7px;
      left: 7px;
    }
  }
  .float-add-button-1 {
    bottom: 126px !important;
    background-color: $primary;
  }
  .addmedia {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $white;
    z-index: 100;
    .content {
      height: calc(100vh - 168px);
      overflow-y: scroll;
    }
    .footer {
      background-color: $white;
      height: 58px;
      position: fixed;
      bottom: 0;
      width: 100%;
      border-top: 1px solid $gray-slate-300;
    }
  }

  datnek-ds-image-text-dropdown {
    margin-bottom: 0;
  }
  @media (min-width: 768px) and (max-width: 991.98px) {
    .network-upload {
      height: 250px !important;
    }
  }

  .sticky-side {
    position: sticky;
    top: 76px;
    @media (max-width: 767.98px) {
      position: relative;
      top: unset;
    }
  }

  .p-chips .p-chips-multiple-container .p-chips-token {
    padding: 0.25rem 0.5rem;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
    background: $primary !important;
    color: $white !important;
    border-radius: 5px;
  }

  .p-chips .p-chips-multiple-container:not(.p-disabled):hover {
    border-color: $primary !important;
    border: 2px solid;
  }

  .p-chips .p-chips-multiple-container:not(.p-disabled).p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none !important;
    border: 2px solid !important;
    border-color: $primary !important;
  }

  .p-chips {
    display: inline-flex;
    width: 100%;
  }

  .p-chips .p-chips-multiple-container {
    padding: 0.25rem 0.5rem;
    width: 100%;
    min-height: 58px;
    border-radius: 6px;
  }

  .p-chips .p-chips-multiple-container:not(.p-disabled):hover {
    border-color: $primary;
    border: 2px solid;
  }

  .p-chips .p-chips-multiple-container .p-chips-token {
    padding: 0.25rem 0.5rem;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
    background: rgba($primary, 35%);
    color: #495057;
    border-radius: 5px;
  }

  .p-chips-input-token input:active {
    border: 0px !important;
  }
}

// TAG LIST ====================================================
.tag-list {
  border-radius: $round-card;
  background-color: $white;
  .tag {
    cursor: pointer;
    .number {
      color: $gray-300;
      margin-left: 1rem;
    }
    &:hover {
      background-color: $primary;
      color: $white;
      .number {
        color: $white;
      }
    }
  }
}

// CONTACTS -----------------------------------------------
.contacts {
  &.content {
    padding-top: 5rem;
    min-height: 100vh;

    .header {
      height: 70px;
      border-bottom: 1px solid $gray-slate-300;
    }
    .footer {
      height: 70px;
      border-top: 1px solid $gray-slate-300;
    }
  }

  .checkbox {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 24px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    &:hover input ~ .checkmark {
      background-color: #ccc;
    }
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
      &:checked ~ .checkmark {
        background-color: $primary;
        &:after {
          display: block;
        }
      }
    }

    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 20px;
      width: 20px;
      background-color: $white;
      border-radius: 4px;
      border: 1px solid lightgrey;
      &:after {
        content: '';
        position: absolute;
        display: none;
        left: 7px;
        top: 3px;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }
  }

  .table thead th {
    vertical-align: middle;
    height: 60px;
    border-bottom: 1px solid $gray-slate-200 !important;
  }

  .table th,
  .table td {
    padding: 0.55rem;
    vertical-align: middle;
    border-top: 0px solid transparent !important;
  }

  .pagination {
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: 0.25rem;
    margin-bottom: 0 !important;
  }

  .page-item.active .page-link {
    background-color: $primary;
    border-color: $primary;
    transform: scale(1.2);
    border-radius: 4px;
  }

  .page-link {
    position: relative;
    display: block;
    padding: 0.5rem 0.75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: $text;
    background-color: $gray-slate-200;
    border: 1px solid #dee2e6;
    &:hover {
      background-color: $gray-slate-300;
    }
  }

  select {
    word-wrap: normal;
    padding: 11px;
    border-radius: 4px;
  }

  .body-content {
    min-height: calc(100vh - 175px);
  }

  .full-height {
    height: calc(100vh - 100px);
  }
}

// EVENTS ------------------------------------------------
.events {
  &.content {
    padding-top: 5rem;
    ul.sidebar-menu {
      padding: 0;
      list-style: none;
      li {
        padding: 8px;
        transition: all ease-in 0.15s;
        &:hover {
          border-radius: $round-card;
          background-color: $home-gray;
        }
        a {
          text-decoration: none;
        }
      }
    }

    .header {
      height: 70px;
      border-bottom: 1px solid $gray-slate-300;
    }
  }

  .calendar-header {
    height: 52px;
    border-bottom: 1px solid $gray-300;
    .bordered-button {
      border-radius: $round;
      border: 2px solid $gray-slate-300;
      &:hover {
        border: 2px solid $primary;
      }
    }
    .left {
      width: 74%;
    }
    .right {
      height: 34px;
      width: 26%;
    }
    .datnek-border-dorpdown {
      border-radius: $round;
      border: 2px solid $gray-slate-300;
      color: $gray-slate-300;
      height: inherit;
      display: flex;
      align-items: center;
    }
  }

  .sidebar-event {
    max-height: calc(100vh - 90px);
    overflow-y: scroll;
    // padding-top: 12px;
    -ms-overflow-style: none !important;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  nk-shared-card-user-box {
    position: relative;
    top: 0;
  }

  .p-tabview .p-tabview-panels {
    background: #ffffff;
    padding: 0;
    border: 0 none;
    color: #495057;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
  }
  .cal-cell-row.cal-header {
    font-weight: 400 !important;
  }
  .cal-week-view .cal-time {
    font-weight: 400 !important;
  }

  .cal-week-view {
    font-weight: 400 !important;
    b {
      font-weight: 400 !important;
    }
  }

  .cal-month-view {
    .cal-open-day-events {
      background-color: $gray-slate-200;
      box-shadow: none;
    }
    .cal-event-title {
      color: $text;
    }
    .cal-day-badge {
      background-color: $primary;
    }
    .cal-day-cell.cal-weekend .cal-day-number {
      color: $primary;
    }
  }

  @media (max-width: 991.98px) {
    .p-scrollpanel.p-component {
      height: calc(100vh - 507px) !important;
    }
  }
  @media (min-width: 992px) {
    .p-scrollpanel.p-component {
      height: calc(100vh - 135px) !important;
    }
  }
}

// SUGGESTION PAGE ===============================================
.suggestion-page {
  .suggestion-box .suggestion-content {
    max-height: unset !important;
  }
}

// FOLLOWERS PAGE ===============================================
.followers-page {
  .suggestion-box .suggestion-content {
    max-height: unset !important;
  }
}

// Single event =================================================
.single-event {
  &.content {
    padding-top: 5rem;
    min-height: calc(100vh - 50px);
  }

  .header {
    height: 60px;
    border-bottom: 1px solid $gray-slate-300;
  }

  .gap-16 {
    gap: 16px;
  }

  .metadata {
    padding: 16px;
    background: $secondary;
    border-radius: 13px;
  }

  .featured {
    width: 100%;
    height: 300px;
    img {
      width: inherit;
      height: inherit;
      object-fit: cover;
    }
  }

  .actions {
    width: 100%;
    margin: auto;
    datnek-ds-button,
    .dropdown,
    .dropup {
      flex: 1;
    }
  }

  .medias {
    position: sticky;
    top: 5rem;
  }
}

// NOTIFICATIONS --------------------------------------------------------
.notifications {
  &.content {
    padding-top: 5rem;
    min-height: 100vh;

    .header {
      height: 70px;
      border-bottom: 1px solid $gray-slate-300;
    }
    .footer {
      height: 70px;
      border-top: 1px solid $gray-slate-300;
    }
  }

  .filter {
    display: flex;
    gap: 16px;
    .chip {
      width: auto;
      background-color: $home-gray;
      padding: 0rem 1.4rem;
      border-radius: 26px;
      display: flex;
      align-items: center;
      height: 30px;
      cursor: pointer;
      &.active {
        background-color: $primary;
        color: $white;
      }
    }
  }

  .notification-list {
    background-color: $white;
    .message-item {
      cursor: pointer;
      img {
        width: 50px;
        height: 50px;
        border-radius: 6px;
        object-fit: cover;
      }
      .infos {
        width: 100%;
      }
      datnek-ds-svg-icon {
        display: none;
      }
      &:hover {
        datnek-ds-svg-icon {
          display: block;
        }
      }
    }
    .display-5 {
      color: $gray-300;
    }
  }

  .read {
    background: $notification-read-bg;
    .display-2 {
      font-weight: 400 !important;
    }
    .infos {
      span {
        font-weight: 500;
      }
    }
  }

  .infos {
    span {
      font-weight: 700;
    }
  }

  .avatar {
    position: relative;
    .icon {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background-color: $primary;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 16px;
        height: 16px;
      }
    }
  }
}

// PAIEMENT CHECKOUT ----------------------------------------------
.paiement-checkout {
  &.content {
    padding-top: 5rem;
    min-height: 100vh;
  }
  .content-header {
    display: flex;
    height: 58px;
    align-items: center;
    padding: 16px;
    border-bottom: 1px solid $gray-300;
    position: relative;
  }

  .price-details {
    border: 1px solid $gray-300;
  }

  .form {
    max-width: 500px;
    margin: auto;
  }

  .p-tabview-nav {
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    margin: 0;
    padding: 0;
    gap: 16px;
    background: #ffffff;
    border: 0px solid #dee2e6;
    border-width: 0 0 0 0;
  }

  .p-tabview .p-tabview-panels {
    padding: 1rem 0;
  }

  .p-tabview .p-tabview-nav li {
    margin-right: 0;
    flex: 1;
  }

  .p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0 transparent;
    border: 2px solid $primary;
  }

  .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    background: #ffffff;
    border: 2px solid $primary;
    border-color: $primary;
    border-radius: 13px;
    color: #000000;
  }

  .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    border: solid #dee2e6;
    border-width: 0 0 0px 0;
    border-color: transparent transparent #dee2e6 transparent;
    background: #ffffff;
    color: #6c757d;
    margin: 0 0 -2px 0;
  }

  .p-accordion
    .p-accordion-header
    .p-accordion-header-link
    .p-accordion-toggle-icon {
    margin-right: 0.5rem;
    display: none;
  }

  .p-accordion .p-accordion-header .p-accordion-header-link {
    padding: 0.4rem 1rem;
    border: 0;
    border-bottom: 1px solid #e0e0e0 !important;
    color: #495057;
    background: #ffffff;
    font-weight: 600;
    border-radius: 3px;
    transition: box-shadow 0.2s;
  }

  .p-accordion
    .p-accordion-header:not(.p-disabled).p-highlight
    .p-accordion-header-link {
    background: #fff;
    border-color: #dee2e6;
    color: #495057;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    &:hover {
      background: #fff;
    }
  }

  .p-accordion
    .p-accordion-header:not(.p-disabled)
    .p-accordion-header-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0rem #a6d5fa;
  }

  .p-accordion .p-accordion-content {
    padding: 1rem;
    border-top: 1px solid #dee2e6;
    border-bottom: 1px solid #dee2e6;
    border-left: 0px solid #dee2e6;
    border-right: 0px solid #dee2e6;
    background: #ffffff;
    color: #495057;
    border-top: 0;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
  }
}

// SAVED MESSAGES -----------------------------------------
.saved-messages-page {
  &.datnek-ds-content {
    min-height: 100vh;
  }
  .suggestion-content {
    background-color: $white;
  }

  .suggestion-header {
    background-color: $white;
    border-radius: $round-card $round-card 0 0;
    .nk-shared-user-infos-small {
      datnek-ds-avatar {
        width: 58px;

        @media (max-with: 400px) {
          width: 43px;
          img {
            width: 43px;
            height: 43px;
          }
        }
      }
      .infos {
        width: 100%;
      }
    }
  }

  .category-body {
    height: calc(100vh - 273px);
    overflow-y: scroll;
    -ms-overflow-style: none !important;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .suggestion-footer {
    background-color: $white;
  }

  .suggestion-header {
    padding: 1rem;
    border-bottom: 1px solid $gray-slate-300;
    @media (max-width: 575.98px) {
      padding: 1rem;
    }
  }

  .category {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    padding: 0 1rem;
    border-bottom: 1px solid $gray-slate-300;
    transition: all ease-in 0.15s;
    datnek-ds-svg-icon {
      display: none;
    }
    &:hover {
      background-color: $home-gray;
      datnek-ds-svg-icon {
        display: block;
      }
    }
  }

  .filter {
    display: flex;
    gap: 16px;
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    @media (max-width: 991.98px) {
      -ms-overflow-style: none !important;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
    }
    &::-webkit-scrollbar {
      display: none;
    }
    .chip {
      min-width: -moz-fit-content;
      min-width: fit-content;
      background-color: $home-gray;
      padding: 0rem 1.4rem;
      border-radius: 26px;
      display: flex;
      align-items: center;
      height: 30px;
      cursor: pointer;
      &.active {
        background-color: $primary;
        color: $white;
      }
    }
  }

  .saved-messages {
    background-color: $white;
    .message-item {
      img {
        width: 50px;
        height: 50px;
        border-radius: 6px;
        object-fit: cover;
      }
      .infos {
        width: 100%;
      }
    }
    .display-5 {
      color: $gray-300;
    }
  }
}

// SINGLE ARTICLE ------------------------------------------
.single-article {
  padding-top: 5rem;
  .sticky-sidebar {
    @media (min-width: 768px) {
      position: sticky;
      top: 70px;
    }
  }
  .title-text {
    font-size: xx-large;
    font-weight: 600;
  }

  .categories {
    margin-top: -5px;
  }

  .header {
    min-height: 70px;
    border-bottom: 1px solid $gray-slate-300;
  }

  .metadata {
    background-color: $white;
  }

  .img {
    margin-left: -16px;
    margin-right: -16px;
  }

  nk-shared-adaptative-image-gallery {
    width: 70%;
    display: flex;
    justify-content: center;
    margin: auto;
    @media (max-width: 575.98px) {
      width: 100%;
    }
  }

  .nk-shared-user-infos-small {
    transition: all ease-in 0.15s;
    &:hover {
      text-decoration: none;
      background-color: $home-gray;
      border-radius: $round-card;
    }
    .infos {
      width: 100%;
      &:hover {
        text-decoration: none;
      }
      .name {
        color: $text;
      }
      span {
        color: $gray-300;
      }
    }
    .datnek-ds-avatar--medium {
      height: 45px;
      width: $img-tiny;
    }
  }

  .actions {
    position: sticky;
    margin-top: 20px;
    margin-left: -6px;
    top: 75px;
    .like-btn {
      position: relative;
      opacity: 1;
      flex: 1;
      .likes-icons {
        position: absolute;
        gap: 10px;
        top: -12px;
        left: -222px;
        padding: 16px;
        cursor: pointer;
        transition: all ease-in 0.18s;
        z-index: 1;
        border-radius: 13px;
        img {
          &:hover {
            transform: scale(1.5);
            transform-origin: center;
          }
        }
      }
    }
  }

  .datnek-ds-card-likes-buttons {
    .buttons-icons {
      display: flex;
      align-items: center;
      width: 100%;
      gap: 14px;
      datnek-ds-button,
      button {
        flex: 1;
      }
    }
    .like-btn {
      position: relative;
      opacity: 1;
      flex: 1;
      .likes-icons {
        position: absolute;
        gap: 10px;
        top: -70px;
        left: -20px;
        padding: 16px;
        cursor: pointer;
        transition: all ease-in 0.18s;
        z-index: 1;
        border-radius: 13px;
        img {
          &:hover {
            transform: scale(1.5);
            transform-origin: center;
          }
        }
      }
    }
  }

  .like-btn {
    position: relative;
    opacity: 1;
    flex: 1;
    .likes-icons {
      position: absolute;
      display: none;
      gap: 10px;
      top: -8px;
      left: -227px;
      padding: 16px;
      cursor: pointer;
      transition: all ease-in 0.18s;
      img {
        &:hover {
          transform: scale(1.5);
          transform-origin: center;
        }
      }
    }
  }

  .datnek-ds-card-likes {
    .left {
      gap: 13px;
    }
  }

  .list-comments {
    border-top: 1px solid #d6d6d6;
    margin-left: -16px;
    margin-right: -16px;
    padding: 16px;
    .filter {
      overflow-x: unset !important;
      overflow-y: unset !important;
    }
  }

  .add-comments {
    background: white;
    //border-top: 1px solid #d6d6d6;
    //padding: 16px;
    border-radius: 0 0 13px 13px;
    margin-bottom: 16px;
  }
}

// SINGLE POST PAGE ========================================
.single-post-page {
  margin-top: 75px;
  .datnek-ds-card {
    border-radius: $round-card;
    overflow: hidden;
  }

  .content {
    padding-top: 5rem;
    min-height: 100vh;
    // ul.sidebar-menu {
    //   padding: 0;
    //   list-style: none;
    //   li {
    //     padding: 8px;
    //     transition: all ease-in 0.15s;
    //     &:hover {
    //       border-radius: $round-card;
    //       background-color: $home-gray;
    //     }
    //     a {
    //       text-decoration: none;
    //     }
    //   }
    // }

    .header {
      height: 70px;
      border-bottom: 1px solid $gray-slate-300;
    }
    .footer {
      height: 70px;
      border-top: 1px solid $gray-slate-300;
    }
  }

  .header {
    height: 70px;
    border-bottom: 1px solid $gray-slate-300;
  }

  .avatar {
    position: relative;
    .icon {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background-color: $primary;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 16px;
        height: 16px;
      }
    }
  }

  .infos {
    span {
      font-weight: 700;
    }
  }

  .notification-list {
    background-color: $white;
    height: 510px;
    //overflow-y: scroll;
    .message-item {
      cursor: pointer;
      img {
        width: 20px;
        height: 20px;
        border-radius: 6px;
        object-fit: cover;
      }
      .infos {
        width: 100%;
      }
    }
    .display-5 {
      color: $gray-300;
    }
  }

  //  .dropdown-formly-menu.show {
  //   transform: translate(-185px, 32px) !important;
  // }

  .allnotifs {
    position: relative;
    height: 42px;
    .notif-list {
      position: fixed;
      z-index: 2;
      top: 75px;
      @media (max-width: 575.99px) {
        top: 75px;
        left: 16px;
      }
      .datnek-ds-card {
        position: absolute;
        top: 55px;
        left: 0;
        z-index: 2;
        width: 400px;
        @media (max-width: 575.98px) {
          left: -16px;
          width: 100vw;
        }
      }
    }
  }
}

// CHALLENGE DESCRIPTION ======================================
.blank-page {
  &.content {
    padding-top: 5rem;
    min-height: 100vh;
  }
}

// HOMEPAGE
.homepage {
  p {
    font-size: 16px !important;
    line-height: 25px !important;
    color: #555555;
  }

  h2 {
    font-size: 39px !important;
    line-height: 42px;
  }

  .hero {
    background-color: $secondary;
    min-height: 600px;
    position: relative;
    margin-top: 55px;
    overflow: hidden;
    @media (max-width: 575.98px) {
      min-height: 500px;
      padding-bottom: 15rem !important;
      padding-top: 10rem !important;
    }
    .hero-title {
      font-size: 48px !important;
      font-weight: 600;
      line-height: 56px;
      z-index: 1;
      position: relative;
      //margin-left: -50px;
      @media (max-width: 575.98px) {
        font-size: 38px !important;
        line-height: 38px;
      }
      @media (max-width: 765.98px) {
        margin-left: 0px;
      }
    }
    .btn_join_now{
      //margin-left: -45px;
      @media (max-width: 765.98px) {
        margin-left: 0px;
      }
    }
    .profiles-map {
      position: absolute;
      width: 800px;
      height: 700px;
      top: -43px;
      left: -86px;
      @media (max-width: 575.98px) {
        transform: scale(0.8);
        transform-origin: left;
      }
      .network {
        position: relative;
        width: auto;
        z-index: 1;
        @media (max-width: 575.98px) {
          width: 100%;
        }
      }
      .point {
        position: absolute;
        width: 60px;
        height: 60px;
        left: 458px;
        top: 223px;
        border-radius: 50%;
        background-color: rgba($primary, 50%);
        animation: 2s scale-point-map infinite;
        &.center {
          animation: 2s scale-point-map 1s infinite;
          left: 0;
          top: 0;
        }
      }
      .point1 {
        position: absolute;
        width: 40px;
        height: 40px;
        left: 415px;
        top: 108px;
        border-radius: 50%;
        background-color: rgba($primary, 50%);
        animation: 2s scale-point-map infinite;
        &.center {
          animation: 2s scale-point-map 1s infinite;
          left: 0;
          top: 0;
        }
      }
      .point2 {
        position: absolute;
        width: 40px;
        height: 40px;
        left: 215px;
        top: 246px;
        border-radius: 50%;
        background-color: rgba($primary, 50%);
        animation: 2s scale-point-map 0.3s infinite;
        &.center {
          animation: 2s scale-point-map 1.3s infinite;
          left: 0;
          top: 0;
        }
      }
      .point3 {
        position: absolute;
        width: 30px;
        height: 30px;
        left: 176px;
        top: 84px;
        border-radius: 50%;
        background-color: rgba($primary, 50%);
        animation: 2s scale-point-map 0.5s infinite;
        &.center {
          animation: 2s scale-point-map 1.5s infinite;
          left: 0;
          top: 0;
        }
      }
    }
    // .network {
    //   position: absolute;
    //   top: 0;
    //   right: -195px;
    //   width: 145%;
    //   @media (max-width: 575.98px) {
    //     right: -80px;
    //   }
    //   .map_line {
    //     width: inherit;
    //     height: inherit;
    //     position: absolute;
    //     @media (max-width: 575.98px) {
    //       transform: scale(0.6);
    //       transform-origin: center;
    //       left: -179px;
    //       top: 8px;
    //     }
    //     img {
    //       position: absolute;
    //       width: 100px;
    //       height: 100px;
    //       animation: bounceIn 1s both;
    //       &.a1 {
    //         left: 405px;
    //         top: 48px;
    //         animation-delay: 1s;
    //       }
    //       &.a2 {
    //         left: 512px;
    //         top: 124px;
    //         width: 70px;
    //         height: 70px;
    //         animation-delay: 2s;
    //       }
    //       &.a3 {
    //         left: 649px;
    //         top: 110px;
    //         animation-delay: 3s;
    //       }
    //       &.a4 {
    //         left: 544px;
    //         top: 205px;
    //         animation-delay: 14;
    //       }
    //       &.a5 {
    //         left: 384px;
    //         top: 224px;
    //         animation-delay: 5s;
    //       }
    //       &.a6 {
    //         left: 214px;
    //         top: 283px;
    //         width: 60px;
    //         height: 60px;
    //         animation-delay: 6s;
    //       }
    //       &.a7 {
    //         left: 217px;
    //         top: 127px;
    //         width: 78px;
    //         height: 78px;
    //         animation-delay: 7s;
    //       }
    //       &.a8 {
    //         left: 807px;
    //         top: 116px;
    //         width: 75px;
    //         height: 75px;
    //         animation-delay: 8s;
    //       }
    //     }
    //   }
    // }

    .flou1,
    .flou2,
    .flou3 {
      position: absolute;
    }
    .flou1 {
      top: -101px;
      left: calc(50% - 213px);
      @media (max-width: 575.98px) {
        top: -139px;
      }
    }
    .flou2 {
      top: 67px;
      left: 65%;
      @media (max-width: 575.98px) {
        top: 25px;
        left: 72%;
      }
    }
    .flou3 {
      bottom: -250px;
      left: -165px;
    }
  }

  @keyframes bounceIn {
    0%,
    20%,
    40%,
    60%,
    80%,
    100% {
      -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    0% {
      opacity: 0;
      -webkit-transform: scale3d(0.3, 0.3, 0.3);
      transform: scale3d(0.3, 0.3, 0.3);
    }
    20% {
      -webkit-transform: scale3d(1.1, 1.1, 1.1);
      transform: scale3d(1.1, 1.1, 1.1);
    }
    40% {
      -webkit-transform: scale3d(0.9, 0.9, 0.9);
      transform: scale3d(0.9, 0.9, 0.9);
    }
    60% {
      opacity: 1;
      -webkit-transform: scale3d(1.03, 1.03, 1.03);
      transform: scale3d(1.03, 1.03, 1.03);
    }
    80% {
      -webkit-transform: scale3d(0.97, 0.97, 0.97);
      transform: scale3d(0.97, 0.97, 0.97);
    }
    100% {
      opacity: 1;
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
  }

  // SERVICES
  .avantages {
    padding: 5rem 0;
    @media (max-width: 575.08px) {
      padding: 1rem;
    }
    .avantage {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      img {
        width: 75px;
        margin: auto;
      }
      h2 {
        font-size: 23px !important;
        line-height: 35px;
        padding: 1rem 3rem;
        @media (max-width: 575.98px) {
          font-size: 19px !important;
        }
      }
    }
  }

  // CHAT
  .chat {
    background-color: $white;
    min-height: 620px;
    position: relative;
    overflow: hidden;
    align-items: stretch !important;
    padding: 4rem 1rem !important;
    @media (max-width: 767.98px) {
      height: auto;
    }
    h2 {
      font-size: 39px !important;
      font-weight: 600;
      line-height: 42px;
      @media (max-width: 575.98px) {
        font-size: 22px !important;
        line-height: 28px;
      }
    }
    @media (max-width: 575.98px) {
      .chatters {
        padding-bottom: 15rem;
      }
    }

    .icon-top-title {
      width: auto;
    }
    .users-chat {
      position: absolute;
      top: 0;
      right: 0;
    }
    .trait,
    .phone,
    .camera,
    .chat-icon,
    .chat-alt,
    .microphone,
    .video-camera,
    .photograph {
      position: absolute;
      width: auto;
    }
    .trait {
      bottom: -16px;
      left: 50%;
      transform: translateX(-50%);
    }
    .phone {
      top: 136px;
      left: 50%;
    }
    .camera {
      bottom: 100px;
      left: 50%;
      transform: translateX(-300px);
    }
    .chat-icon {
      bottom: 23px;
      left: 50%;
      transform: translateX(-461px);
    }
    .chat-alt {
      bottom: 76px;
      left: 50%;
      transform: translateX(-562px);
    }
    .video-camera {
      bottom: 41px;
      right: 84px;
    }
    .microphone {
      bottom: 7px;
      left: 50%;
      transform: translateX(-183px);
    }
    .photograph {
      top: 41px;
      right: 84px;
    }
  }

  .challenge {
    .icon-top-title {
      width: 55px !important;
    }
    @media (max-width: 575.98px) {
      .winners {
        width: 60%;
      }
    }
  }

  .call-4-action {
    background-color: $gray-slate-200;
    min-height: 550px;
    position: relative;
    overflow: hidden;
    @media (max-width: 575.98px) {
      padding-bottom: 13rem !important;
    }
    h2 {
      font-size: 39px !important;
      font-weight: 600;
      line-height: 42px;
      @media (max-width: 575.98px) {
        font-size: 29px !important;
      }
    }
    .network {
      position: absolute;
      bottom: -104px;
      //right: -195px;
      width: 145%;
      @media (max-width: 575.98px) {
        right: -80px;
        top: 0;
        bottom: unset;
      }
      .map_line {
        width: inherit;
        height: inherit;
        position: absolute;
        @media (max-width: 575.98px) {
          transform: scale(0.6);
          transform-origin: center;
          left: -179px;
          top: 8px;
        }
        img {
          position: absolute;
          width: 100px;
          height: 100px;
          animation: bounceIn 1s both;
          &.a1 {
            left: 405px;
            top: 48px;
            animation-delay: 1s;
          }
          &.a2 {
            left: 512px;
            top: 124px;
            width: 70px;
            height: 70px;
            animation-delay: 2s;
          }
          &.a3 {
            left: 649px;
            top: 110px;
            animation-delay: 3s;
          }
          &.a4 {
            left: 544px;
            top: 205px;
            animation-delay: 14;
          }
          &.a5 {
            left: 384px;
            top: 224px;
            animation-delay: 5s;
          }
          &.a6 {
            left: 214px;
            top: 283px;
            width: 60px;
            height: 60px;
            animation-delay: 6s;
          }
          &.a7 {
            left: 217px;
            top: 127px;
            width: 78px;
            height: 78px;
            animation-delay: 7s;
          }
          &.a8 {
            left: 807px;
            top: 116px;
            width: 75px;
            height: 75px;
            animation-delay: 8s;
          }
        }
      }
    }
    // .profiles-map {
    //   position: absolute;
    //   width: 800px;
    //   height: 700px;
    //   top: -43px;
    //   left: -86px;
    //   .network {
    //     position: relative;
    //     width: auto;
    //     z-index: 1;
    //     @media (max-width: 575.98px) {
    //       width: 100%;
    //     }
    //   }
    //   .point {
    //     position: absolute;
    //     width: 60px;
    //     height: 60px;
    //     left: 458px;
    //     top: 223px;
    //     border-radius: 50%;
    //     background-color: rgba($primary, 50%);
    //     animation: 2s scale-point-map infinite;
    //     &.center {
    //       animation: 2s scale-point-map 1s infinite;
    //       left: 0;
    //       top: 0;
    //     }
    //   }
    //   .point1 {
    //     position: absolute;
    //     width: 40px;
    //     height: 40px;
    //     left: 415px;
    //     top: 108px;
    //     border-radius: 50%;
    //     background-color: rgba($primary, 50%);
    //     animation: 2s scale-point-map infinite;
    //     &.center {
    //       animation: 2s scale-point-map 1s infinite;
    //       left: 0;
    //       top: 0;
    //     }
    //   }
    //   .point2 {
    //     position: absolute;
    //     width: 40px;
    //     height: 40px;
    //     left: 215px;
    //     top: 246px;
    //     border-radius: 50%;
    //     background-color: rgba($primary, 50%);
    //     animation: 2s scale-point-map 0.3s infinite;
    //     &.center {
    //       animation: 2s scale-point-map 1.3s infinite;
    //       left: 0;
    //       top: 0;
    //     }
    //   }
    //   .point3 {
    //     position: absolute;
    //     width: 30px;
    //     height: 30px;
    //     left: 176px;
    //     top: 84px;
    //     border-radius: 50%;
    //     background-color: rgba($primary, 50%);
    //     animation: 2s scale-point-map 0.5s infinite;
    //     &.center {
    //       animation: 2s scale-point-map 1.5s infinite;
    //       left: 0;
    //       top: 0;
    //     }
    //   }
    // }
  }

  .testimony {
    background-color: $primary;
    padding: 4rem 0;
    color: $white;
    text-align: center;
    @media (max-width: 575.08px) {
      padding: 1rem;
    }
    .item {
      width: 500px;
      @media (max-width: 575.98px) {
        width: auto;
        padding: 0 2.4rem;
      }
      img {
        margin: auto;
        width: auto;
      }
      p {
        color: $white !important;
      }
      .name {
        font-size: 17px;
        font-weight: 600;
        font-family: 'Open Sans';
        margin-bottom: 1rem;
      }
      .fonction {
        font-size: 14px;
        font-weight: 400;
        font-family: 'Open Sans';
        margin-bottom: 1rem;
      }
    }
  }

  .top-footer {
    padding: 120px 0 190px;
    background-color: #f0f4f6;
    position: relative;
    text-align: center;
    justify-items: center;
    overflow: hidden;
    @media only screen and (max-width: 1199px) {
      padding: 95px 0 190px;
    }
    @media only screen and (max-width: 991px) {
      padding: 75px 0 180px;
    }
    @media only screen and (max-width: 479px) {
      padding: 75px 0 80px;
    }

    h2 {
      font-size: 39px !important;
      font-weight: 600;
      line-height: 42px;
      @media (max-width: 575.98px) {
        font-size: 29px !important;
      }
    }
    datnek-ds-button {
      margin: auto;
    }
    .user-group {
      position: relative;
      bottom: -110px;
    }
  }

  // OWL CAROUSEL
  .owl-theme .owl-nav [class*='owl-'] {
    color: #fff;
    font-size: 14px;
    margin: 5px;
    padding: 4px 4px;
    background: transparent !important;
    display: inline-block;
    cursor: pointer;
    border-radius: 3px;
  }

  .owl-prev {
    left: 0;
    position: absolute;
  }

  .owl-next {
    right: 0;
    position: absolute;
  }
  .owl-theme .owl-nav {
    margin-top: -16px;
    position: absolute;
    top: 50%;
    width: 100%;
  }

  // POINT MAP ANIMATION
  @keyframes scale-point-map {
    0% {
      -webkit-transform: scale(1);
      transform: scale(1);
      -webkit-transform-origin: center;
      transform-origin: center;
    }
    50% {
      -webkit-transform: scale(2);
      transform: scale(2);
      opacity: 0.55;
      -webkit-transform-origin: center;
      transform-origin: center;
    }
    100% {
      -webkit-transform: scale(2);
      transform: scale(2);
      opacity: 0;
      -webkit-transform-origin: center;
      transform-origin: center;
    }
  }

  // POINT MAP ANIMATION
  @keyframes scale-point-map {
    0% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
    50% {
      -webkit-transform: scale(2);
      transform: scale(2);
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(2);
      transform: scale(1.32);
      opacity: 0;
    }
  }

  // FLOATING ANIMATION
  .floating {
    -webkit-animation: floating 3s ease infinite;
    animation: floating 3s ease infinite;
    will-change: transform;
  }
  .floating:hover {
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
  }

  .floating-lg {
    -webkit-animation: floating-lg 3s ease infinite;
    animation: floating-lg 3s ease infinite;
  }

  .floating-sm {
    -webkit-animation: floating-sm 3s ease infinite;
    animation: floating-sm 3s ease infinite;
  }

  @-webkit-keyframes floating-lg {
    0% {
      -webkit-transform: translateY(0px);
      transform: translateY(0px);
    }
    50% {
      -webkit-transform: translateY(15px);
      transform: translateY(15px);
    }
    100% {
      -webkit-transform: translateY(0px);
      transform: translateY(0px);
    }
  }

  @keyframes floating-lg {
    0% {
      -webkit-transform: translateY(0px);
      transform: translateY(0px);
    }
    50% {
      -webkit-transform: translateY(15px);
      transform: translateY(15px);
    }
    100% {
      -webkit-transform: translateY(0px);
      transform: translateY(0px);
    }
  }
  @-webkit-keyframes floating {
    0% {
      -webkit-transform: translateY(0px);
      transform: translateY(0px);
      opacity: 1;
    }
    50% {
      -webkit-transform: translateY(10px);
      transform: translateY(10px);
    }
    100% {
      -webkit-transform: translateY(0px);
      transform: translateY(0px);
    }
  }
  @keyframes floating {
    0% {
      -webkit-transform: translateY(0px);
      transform: translateY(0px);
    }
    50% {
      -webkit-transform: translateY(10px);
      transform: translateY(10px);
    }
    100% {
      -webkit-transform: translateY(0px);
      transform: translateY(0px);
    }
  }
  @-webkit-keyframes floating-sm {
    0% {
      -webkit-transform: translateY(0px);
      transform: translateY(0px);
    }
    50% {
      -webkit-transform: translateY(5px);
      transform: translateY(5px);
    }
    100% {
      -webkit-transform: translateY(0px);
      transform: translateY(0px);
    }
  }
  @keyframes floating-sm {
    0% {
      -webkit-transform: translateY(0px);
      transform: translateY(0px);
    }
    50% {
      -webkit-transform: translateY(5px);
      transform: translateY(5px);
    }
    100% {
      -webkit-transform: translateY(0px);
      transform: translateY(0px);
    }
  }

  /**************************************
  ******** SVG HERO ANIMATION ***********
  **************************************/
  svg {
    .st0 {
      fill: none;
      stroke: $primary;
      stroke-width: 3;
      stroke-linecap: round;
      stroke-miterlimit: 10;
      stroke-opacity: 0.2;
      stroke-dasharray: 10;
      &.line_1 {
        animation: dash 20s linear infinite;
      }
    }
    .st1 {
      fill: none;
      stroke: $primary;
      stroke-width: 3;
      stroke-linecap: round;
      stroke-miterlimit: 10;
      // stroke-dasharray: 373;
      // stroke-dashoffset: 373;
      &.line_10 {
        stroke-dasharray: 645;
        stroke-dashoffset: 550;
        animation-duration: 6s;
        animation-name: dash_1000;
        animation-timing-function: ease-in;
        animation-iteration-count: infinite;
      }
      &.line_6 {
        stroke-dasharray: 515;
        stroke-dashoffset: 470;
        animation-duration: 6s;
        animation-name: dash_1000;
        animation-timing-function: ease-in;
        animation-iteration-count: infinite;
      }
      &.line_2 {
        stroke-dasharray: 625;
        stroke-dashoffset: 525;
        animation-duration: 6s;
        animation-name: dash_1000;
        animation-timing-function: ease-in;
        animation-iteration-count: infinite;
      }
      &.line_5 {
        stroke-dasharray: 625;
        stroke-dashoffset: -525;
        animation-duration: 6s;
        animation-name: dash_1000;
        animation-timing-function: ease-in;
        animation-iteration-count: infinite;
      }
      &.line_7 {
        stroke-dasharray: 459;
        stroke-dashoffset: -420;
        animation-duration: 6s;
        animation-name: dash_1000;
        animation-timing-function: ease-in;
        animation-iteration-count: infinite;
      }
      &.line_11 {
        stroke-dasharray: 449;
        stroke-dashoffset: -410;
        animation-duration: 6s;
        animation-name: dash_1000;
        animation-timing-function: ease-in;
        animation-iteration-count: infinite;
      }
      &.line_8 {
        stroke-dasharray: 500;
        stroke-dashoffset: -400;
        animation-duration: 6s;
        animation-name: dash_1000;
        animation-timing-function: ease-in;
        animation-iteration-count: infinite;
      }
      &.line_9 {
        stroke-dasharray: 428;
        stroke-dashoffset: -412;
        animation-duration: 6s;
        animation-name: dash_1000;
        animation-timing-function: ease-in;
        animation-iteration-count: infinite;
      }
      &.line_3 {
        stroke-dasharray: 520;
        stroke-dashoffset: 525;
        //animation-delay: 2s;
        animation-duration: 6s;
        animation-name: dash_1000;
        animation-timing-function: ease-in;
        animation-iteration-count: infinite;
      }
      &.line_4 {
        stroke-dasharray: 625;
        stroke-dashoffset: 525;
        //animation-delay: 5s;
        animation-duration: 6s;
        animation-name: dash_1000;
        animation-timing-function: ease-in;
        animation-iteration-count: infinite;
      }
    }
  }

  @keyframes dash {
    from {
      fill-opacity: 0;
      stroke-dashoffset: 0;
    }
    to {
      stroke-dashoffset: 1000;
      fill-opacity: 1;
    }
  }
  @keyframes dash_1000 {
    to {
      fill-opacity: 0;
      stroke-dasharray: 1000;
      stroke-dashoffset: 0;
    }
  }
  @keyframes dasharrayTo10 {
    from {
      stroke-opacity: 0;
    }
    to {
      stroke-opacity: 1;
      stroke-dasharray: 10;
      stroke-dashoffset: 1000;
    }
  }

  // FOOTER -------------------------------------------------
  .footer-wrap {
    background-color: #f0f4f6;
    position: relative;
    z-index: 1;
    @media (max-width: 575.98px) {
      background-color: #fff;
    }
    &:before {
      content: assetUrl('Img/homepage/footer_shape.png');
      position: absolute;
      bottom: 0;
      left: 50%;
      -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      transform: translateX(-50%);
      z-index: -1;
      overflow: hidden;
      line-height: 0;
    }
    .footer-top-image {
      margin-bottom: 5px;
      li {

        display: inline-block;
        position: absolute;
        top: -103px;
        -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        transform: translateX(-50%);
        z-index: -2;
        &:nth-child(1n) {
          left: 50%;
        }
        &:nth-child(2n) {
          left: calc(50% + 135px);
          top: -100px;
        }
        &:nth-child(3n) {
          left: calc(50% - 135px);
          top: -97px;
        }
        &:nth-child(4n) {
          left: calc(50% + 270px);
          top: -97px;
        }
        &:nth-child(5n) {
          left: calc(50% - 270px);
          top: -88px;
        }
        &:nth-child(6n) {
          left: calc(50% + 405px);
          top: -80px;
        }
        &:nth-child(7n) {
          left: calc(50% - 405px);
          top: -69px;
        }
        &:nth-child(8n) {
          left: calc(50% + 540px);
          top: -60px;
        }
        &:nth-child(9n) {
          left: calc(50% - 540px);
          top: -44px;
        }
      }
    }
  }

  @media only screen and (max-width: 479px) {
    .footer-wrap .footer-top-image {
      display: none;
    }
  }

  @media only screen and (max-width: 767px) {
    .footer-wrap .footer-top-image li:nth-child(4n) {
      display: none;
    }
  }

  @media only screen and (max-width: 767px) {
    .footer-wrap .footer-top-image li:nth-child(5n) {
      display: none;
    }
  }

  @media only screen and (max-width: 991px) {
    .footer-wrap .footer-top-image li:nth-child(6n) {
      display: none;
    }
  }

  @media only screen and (max-width: 991px) {
    .footer-wrap .footer-top-image li:nth-child(7n) {
      display: none;
    }
  }

  @media only screen and (max-width: 1199px) {
    .footer-wrap .footer-top-image li:nth-child(8n) {
      display: none;
    }
  }

  @media only screen and (max-width: 1199px) {
    .footer-wrap .footer-top-image li:nth-child(9n) {
      display: none;
    }
  }

  .main-footer {
    padding: 140px 0 25px;
    @media only screen and (max-width: 991px) {
      background-color: #ffffff;
      padding: 80px 0 25px;
    }
    @media (max-width: 575.98px) {
      padding: 12px 0 25px;
    }
  }

  .footer-box {
    margin-bottom: 50px;
    @media (max-width: 575.98px) {
      margin-bottom: 25px;
    }
    .footer-logo {
      margin-bottom: 20px;
    }
    .footer-title {
      font-size: 22px;
      padding-bottom: 18px;
      margin-bottom: 22px;
      position: relative;
      @media (max-width: 575.98px) {
        padding-bottom: 5px;
        margin-bottom: 22px !important;
      }
      &:after {
        content: '';
        height: 3px;
        width: 20px;
        background-color: #615dfa;
        position: absolute;
        bottom: 0;
        left: 8px;
      }
      &:before {
        content: '';
        height: 3px;
        width: 6px;
        background-color: #615dfa;
        position: absolute;
        bottom: 0;
        left: 0;
      }
    }
    .footer-link ul li {
      margin-bottom: 7px;
      a {
        color: #646464 !important;
        &:hover {
          color: #615dfa;
        }
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .footer-bottom {
    padding: 10px 0;
    border-top: 1px solid #ececec;
    text-align: center;
    .footer-copyright {
      color: #838383;
    }
  }

  footer {
    padding: 2rem 2rem 0 2rem;
    position: relative;
    h4 {
      font-size: 1.2rem;
    }
    ul {
      padding: 0;
      list-style: none;
      a {
        color: $text;
      }
    }
  }

  .btn-img-txt img {
    width: 25px;
  }

  .back-to-top {
    position: absolute;
    right: 16px;
    bottom: 16px;
    font-size: 2rem;
  }

  .columns {
    width: 100%;
    column-count: 4;
    a {
      display: block;
    }
  }

  .header-blue {
    background: $primary;
    color: white;
    h4 {
      font-weight: 500;
      font-size: 1.2rem;
    }
  }

  .modal-body {
    .header-body {
      .langues {
        .active {
          font-weight: 600;
          color: $dark;
        }
      }
    }
    .content-body {
      .columns {
        .active {
          color: $dark;
          font-weight: 500;
        }
      }
      .nav-pills {
        width: 25%;
        li {
          a {
            &.nav-link {
              color: #6a6a6a;
              &.active {
                color: $dark;
                font-weight: 600;
                background: transparent;
              }
            }
          }
        }
      }
      .tab-content {
        flex: 1 1 auto !important;
        width: 100%;
      }
    }
  }
}
