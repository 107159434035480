.suggestion-pitch {
    .suggestion-list {
        position: sticky;
        top: 10px;
        .suggestion-header {
            padding: 1rem 1.5rem;
            border-bottom: 1px solid #e9e7e7;
        }
    }
    .suggestion-header {
        padding: 1rem 1.5rem;
        border-bottom: 1px solid #e9e7e7;
    }
    .suggestion-body {
        video {
            background-color: #161616;
            width: 40%;
        }
    }
    network-suggestion-single {
        .network-user-suggestion-small {
            overflow: hidden;
            transition: all .1 ease .5;
            transform: scale(1);
            .video {
                .embed-responsive {
                    height: 100%;
                    position: absolute;
                    display: block;
                    width: 100%;
                    transform: scale(2.5);
                    padding: 0;
                    overflow: hidden;
                }
            }
            .infos {
                gap: 10px;
            }
        }
    }
}

.suggestion-single {
    &.network-user-suggestion-small{
        overflow: hidden;
        transition: all .15s ease;
        transform: scale(1);
        z-index: 1;
        &.inline-box {
            .video {
                width: 40%;
                height: auto;
                margin-bottom: 0;
                position: relative;
                overflow: hidden;
                background: #4b4b4b;
                .play {
                    position: absolute;
                    width: 50px;
                    height: 50px;
                    left: calc(50% - 25px);
                    top: calc(50% - 25px);
                    z-index: 1;
                }
                video {
                    position: absolute;
                    height: 100%;
                    transform: scale(2.6);
                    display: block;
                    width: 100%;
                    padding: 0;
                    overflow: hidden;
                }
            }
            .infos {
                gap: 10px;
                width: 60%;
                flex: 3;
                padding: 0 0 0 0.8rem;
            }
        }
        .video {
            width: 100%;
            height: 150px;
            margin-bottom: 0;
            position: relative;
            overflow: hidden;
            background: #4b4b4b;
            flex: 1;
            .play {
                position: absolute;
                width: 50px;
                height: 50px;
                left: calc(50% - 25px);
                top: calc(50% - 25px);
                z-index: 1;
                // transform: translateX(7px) scale(.5);
            }
        }
        .infos {
            gap: 10px;
            padding: 1rem 1rem 0;
            .display-8 {
                color: $gray-300;
            }
            .name {
                display: flex;
                align-items: center;
                width: calc(100% - 42px);
                & > div {
                    width: auto;
                }
                img{
                    width: 12px;
                    height: 12px;
                }
            }
            .infos-details {
                width: calc(100% - 23px);
            }
        }
        .stats {
            .followers {
                font-size: 0.8rem;
            }
        }
        .text-left {
            font-size: 0.9rem;
           
        }
        .details {
            width: calc(100% - 25px);
            // display: flex;
            // align-items: center;
            // gap: 5px;
            // span {
            //     width: min(40%, 180px);
            //     @media (max-width: 575.98px) {
            //     width: min(40%, 100px);
            //     }
            // }
        }
        &:hover {
            transform: scale(1.15);
            z-index: 2;
        }
    }
}

.suggestion-list {
    .infos {
        & > div {
            width: calc(100% - 3px);
            //width: auto;
        }
        .name {
            display: flex;
            align-items: center;
            & > div {
                //max-width: min(92%, 117px);
                width: auto;
                position: relative;
                display: inline-block;  
                @media (max-width: 575.98px) {
                    //max-width: min(100%, 222px);
                    width: auto;
                }
            }
            
            img {
                width: 12px !important;
                height: 12px !important;
                // right: -6px;
                // top: 7px;
                // position: relative;
                // margin-left: .5rem;
                //transform: scaleX(0.7) scaleY(0.4);
            }
        }
        span {
        color: $gray-300;
        font-size: 13px !important;
        line-height: 14px;
        &.details {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          //gap: 5px;
          span {
            max-width: min(80%, 200px);
            width: auto;
            @media (max-width: 575.98px) {
              max-width: min(80%, 200px);
                width: auto;
            }
          }
        }
      }
    }
}