.enterprise {
  .owl-theme .owl-nav [class*="owl-"] {
    color: #fff;
    font-size: 14px;
    margin: 5px;
    padding: 4px 4px;
    background: transparent !important;
    display: inline-block;
    cursor: pointer;
    border-radius: 3px;
  }

  .owl-prev {
    left: 0;
    position: absolute;
  }

  .owl-next {
    right: 0;
    position: absolute;
  }
  .owl-theme .owl-nav {
    margin-top: 10px;
    position: absolute;
    top: 160px;
    width: 100%;
  }
}

// ENTERPRISE LIST =======================================
.enterprise-list {
  .suggestion-header {
    background-color: $white;
    border-radius: $round-card $round-card 0 0;
    .nk-shared-user-infos-small {
      datnek-ds-avatar {
        width: 58px;

        @media (max-with: 400px) {
          width: 43px;
          img {
            width: 43px;
            height: 43px;
          }
        }
      }
      .infos {
        width: 100%;
      }
    }
  }
  .suggestion-list {
    position: sticky;
    top: 70px;
  }
  .category-body {
    height: calc(100vh - 273px);
    overflow-y: scroll;
    -ms-overflow-style: none !important;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .p-scrollpanel-content {
    width: calc(100% - -35px) !important;
  }

  .suggestion-footer {
    padding: 0.7rem;
    border-top: 1px solid #e9e7e7;
    border-radius: 0 0 $round-card $round-card;
  }

  .suggestion-header {
    padding: 1rem;
    border-bottom: 1px solid $gray-slate-300;
    @media (max-width: 575.98px) {
      padding: 1rem;
    }
  }

  .category {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    padding: 0 1rem;
    border-bottom: 1px solid $gray-slate-300;
    transition: all ease-in 0.15s;
    img {
      transition: all ease-in 0.15s;
      opacity: 0;
    }
    &:hover {
      background-color: $home-gray;
      img {
        opacity: 1;
      }
    }
  }

  .dropdown-menu.show {
    transform: translate(-155px, 35px) !important;
  }
}
