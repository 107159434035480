
body,
html {
  background-color: $home-gray !important;
}

.app {
  &.bg-container {
    background-color: $home-gray;
    min-height: 100vh !important;
    height: unset !important;
    // overflow-y: scroll;
    // overflow-x: hidden;
    // -ms-overflow-style: none !important;
    // scrollbar-width: none;
    // &::-webkit-scrollbar {
    //   display: none;
    // }
  }

  .p-scrollpanel-content {
    height: calc(100% + 18px) !important;
    width: calc(100% + 18px) !important;
  }

  .p-scrollpanel .p-scrollpanel-bar {
    background: rgba($dark, 6%);
    border: 0 none;
  }

  .p-scrollpanel-wrapper {
    height: 100% !important;
  }
}
