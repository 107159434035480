
.datnek-ds-truncate {
  .truncate-parent {
    padding: 0;
    span {
      margin: 0;
      font-size: 16;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      position: relative;
    }
  }
}


@import "ui/lib/lib";
